import { Icon } from 'components/Icon';
import { PageTitleRow } from 'components/Layout/PageTitleRow';
import { useView } from 'hooks/useView';
import { Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'routing/routes';
import { CalculationHistoryItemModel } from 'services/CalculationHistoryItemService';
import CalculationHistoryService, { CalculationHistoryModel } from 'services/CalculationHistoryService';
import { OmieService } from 'services/OmieService';

export function CalculationHistoryView() {
  const params = useParams<{id: string}>();
  const { model } = useView<CalculationHistoryModel>(CalculationHistoryService, params.id || '');

  function formatCell(value: number) : string | number {
    return value !== 0 ? value : '';
  }

  return (
    <div className="layout-wrapper layout-wrapper-list">
      <PageTitleRow title="Resultado do cálculo">
        <Link className="btn btn-primary" to={ routes.omieCalculationHistory.path }>
          <Icon name="keyboard_arrow_left" className="fs-5 align-middle" />
          Voltar
        </Link>
      </PageTitleRow>
      {/* <div className="mb-4">
        <ListTop
          q={ searchParams.get('q') || '' }
          onSearch={ onSearch }
          searchText="Buscar produtos"
          // toggleFilters={toggleFilters.bind(null, !isFiltersVisible)}
        />
      </div> */}

      <div className="pb-3">
        {/* <CustomersFilters
          isFiltersVisible={isFiltersVisible}
          linkFilter={linkFilter}
          // searchParams={searchParams}
        /> */}
      </div>

      <div className="mb-2">
        <Table className="border-top" striped hover responsive="md">
          <thead>
            <tr>
              <th>Código</th>
              <th>Produto</th>
              <th>Tipo</th>
              <th><span className="text-nowrap">Saldo inicial</span></th>
              <th><span className="text-nowrap">Produzindo</span></th>
              <th><span className="text-nowrap">Comprando</span></th>
              <th><span className="text-nowrap">Pedido/venda</span></th>
              <th><span className="text-nowrap">Insumo</span></th>
              <th><span className="text-nowrap">Estoque min.</span></th>
              <th><span className="text-nowrap">A produzir</span></th>
              <th><span className="text-nowrap">A comprar</span></th>
              <th><span className="text-nowrap">Saldo</span></th>
            </tr>
          </thead>
          <tbody className="border-top">
            {model ? model.items.map((item: CalculationHistoryItemModel, i: number) => {
              const isProduction = OmieService.isProduction(item.vendor_type);

              return (
                <tr key={ item.vendor_id }>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{OmieService.PRODUCT_TYPE_MAP[item.vendor_type].name}</td>
                  <td>{formatCell(item.stock)}</td>
                  <td>{formatCell(item.in_production_qty)}</td>
                  <td>{formatCell(item.in_order_request_qty)}</td>
                  <td>{formatCell(item.sold_qty)}</td>
                  <td>{formatCell(item.sold_as_feedstock_qty)}</td>
                  <td>{formatCell(item.minimum_stock)}</td>
                  <td>{formatCell(isProduction ? item.to_buy_or_produce : 0)}</td>
                  <td>{formatCell(!isProduction ? item.to_buy_or_produce : 0)}</td>
                  <td>{formatCell(item.balance)}</td>
                </tr>
              );
            }) : null}
          </tbody>
        </Table>
      </div>
      {/*

      <Paginator
        currentPage={ pagination.currentPage }
        offset={ pagination.offset }
        total={ pagination.total }
        onPaginate={ onPaginate }
      />

      <Modal
        dialogClassName="mw-1100p"
        contentClassName="p-3"
        show={ formItem !== false }
        onHide={ setFormItem.bind(null, false) }
      >
        {renderModalContent()}
      </Modal>

      <Modal
        show={ deletingModel !== false }
        onHide={ setDeletingModel.bind(null, false) }
      >
        {renderDeleteModalContent()}
      </Modal> */}
    </div>
  );
}
