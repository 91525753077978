import { PCPSmart } from 'assets/PCPSmart';
import { Icon } from 'components/Icon';
import { Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { routes } from 'routing/routes';
import { CustomerUserModel } from 'services/CustomerUserService';

export default function Header({
  customerUser,
  onLogout,
}: {
  customerUser: CustomerUserModel | null;
  onLogout: () => void;
}) {
  // const customerId = customerUser ? customerUser.customer_id?.toString() : '';
  return (
    <header className="header">
      <div className="header-content">
        <h1 className="logo">
          <Link to={ routes.home.path }>
            <PCPSmart />
          </Link>
        </h1>

        <nav className="main-nav">
          <ul className="main-menu">
            <li className="main-menu-li">
              <NavLink
                className="main-menu-link"
                to={ routes.omieCalculation.path }
              >
                <Icon name="manage_search" className="menu-icon" />
                <div>
                  Planejamento
                  <div className="main-menu-link-small">
                    Necessidade de materiais
                  </div>
                </div>
              </NavLink>
            </li>
            <li className="main-menu-li">
              <NavLink className="main-menu-link" to={ `${routes.orderHistoryItem.path}?filter[ind_feedstock]=0` }>
                <Icon name="history" className="menu-icon" />
                <div>
                  Pedidos
                  <div className="main-menu-link-small">
                    Histórico de vendas
                  </div>
                </div>
              </NavLink>
            </li>
            <li className="main-menu-li">
              <NavLink
                className="main-menu-link"
                to={ routes.configurationDashboard.path }
              >
                <Icon name="settings" className="menu-icon" />
                <div>
                  Configurações
                  <div className="main-menu-link-small">
                    Usuários, token e ajustes
                  </div>
                </div>
              </NavLink>
            </li>
          </ul>
        </nav>

        <nav className="auth-nav">
          <ul className="auth-menu">
            <li className="auth-menu-li">
              <span className="auth-menu-link">
                {/* <Link className="auth-menu-link" to={ routes.home.path }> */}
                {customerUser?.name.split(' ').shift()}
                <Icon name="account_circle" className="menu-icon auth-menu-icon" />
                {/* </Link> */}
              </span>
            </li>
            <li className="auth-menu-li">
              <Button
                onClick={ onLogout }
                variant="link"
                className="auth-menu-link"
              >
                Sair
                <Icon name="logout" className="menu-icon auth-menu-icon" />
              </Button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
