import { Icon } from 'components/Icon';
import { LoadingWrapper } from 'components/LoadingWrapper';
import { Slugify } from 'helpers/Slugfy';
import { ChangeEvent } from 'react';
import {
  Alert,
  Button, FormControl, InputGroup, Table,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routing/routes';
import CalculationHistoryService from 'services/CalculationHistoryService';
import NotificationService from 'services/NotificationService';
import { CalculationItem, OmieService } from 'services/OmieService';
import { usePOCalculation } from './Context';
import { POCalculationFilters } from './Filters';

export function CalculationList() {
  const navigate = useNavigate();
  const { state, dispatch } = usePOCalculation();
  if (state.status === 'idle') {
    return null;
  }

  function submit() {
    dispatch({ type: 'setStatus', data: 'pending' });
    CalculationHistoryService.store({
      ind_use_stock: state.ind_use_stock,
      query_from: state.initial_date,
      query_to: state.final_date,
      products: state.productCollection,
    })
      .then((res) => {
        NotificationService.success('Pedidos criados com sucesso.');
        navigate(routes.omieCalculationHistory.path);
      })
      .catch((err) => {
        dispatch({ type: 'setStatus', data: 'error' });
        if (err?.response.status === 422) {
          const genericLis = [];
          const lis = [];
          for (const key in err.response.data.error_data) {
            if (Object.prototype.hasOwnProperty.call(err.response.data.error_data, key)) {
              // const msg = err.response.data.error_data[key][0];
              if (/^products\.\d+\.possui_malha/ig.test(key)) {
                const splited: any = key.split('.');
                lis.push(state.productCollection[splited[1]]);
              } else {
                genericLis.push(err.response.data.error_data[key][0]);
              }
            }
          }
          if (lis.length) {
            NotificationService.danger(
              <div>
                <strong>
                  Ops, verifique se os produtos a seguir possuem
                  estruturas cadastradas corretamente:
                </strong>
                <ul className="mt-3">
                  {lis.map((p) => <li key={ p.codigo_produto }>{p.descricao}</li>)}
                </ul>
              </div>,
            );
            return;
          }
          NotificationService.danger(
            <div>
              <ul className="mt-3">
                {genericLis.map((m) => <li key={ m }>{m}</li>)}
              </ul>
            </div>,
          );
        } else {
          NotificationService.danger('Ops, não conseguimos salvar seu pedido.');
        }
      });
  }

  function onDownlodCsv() {
    if (!state.productCollection.length) {
      return;
    }
    const separator = ';';
    const header = [
      '"Código"',
      '"Produto"',
      '"Tipo"',
      '"Saldo inicial"',
      '"Produzindo"',
      '"Comprando"',
      '"Pedido/venda"',
      '"Insumo"',
      '"Estoque min."',
      '"A produzir"',
      '"A comprar"',
      '"Saldo"',
    ];
    const csv = [
      header.join(separator),
    ];
    state.productCollection.forEach((row) => {
      const isProduction = OmieService.isProduction(row.tipoItem);
      csv.push(
        [
          `"${row.codigo}"`,
          `"${row.descricao}"`,
          `"${OmieService.PRODUCT_TYPE_MAP[row.tipoItem].name}"`,
          `"${formatCell(row.estoque_atual)}"`,
          `"${formatCell(row.qty_em_producao)}"`,
          `"${formatCell(row.qty_em_pedidos_de_compra)}"`,
          `"${formatCell(row.quantidade_vendida)}"`,
          `"${formatCell(row.quantidade_vendida_como_insumo)}"`,
          `"${formatCell(row.estoque_minimo)}"`,
          `"${formatCell(isProduction ? row.compra_ou_producao : 0)}"`,
          `"${formatCell(!isProduction ? row.compra_ou_producao : 0)}"`,
          `"${formatCell(row.saldo)}"`,
        ].join(separator),
      );
    });

    const csvContent = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv.join('\r\n'))}`;
    // const blob = new Blob([csv.join('\r\n')], { type: 'text/csv' });
    // const csvUrl = URL.createObjectURL(blob);
    const now = new Date().toLocaleDateString('pt-BR', {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const link = document.createElement('a');
    link.id = 'lnkDwnldLnk';
    document.body.appendChild(link);
    link.download = `calculo-pcp-smart-${Slugify(now)}.csv`;
    link.href = csvContent;
    // link.href = csvUrl;
    link.click();
    document.body.removeChild(link);
  }

  function formatCell(value: number) : string | number {
    return value !== 0 ? value : '';
  }

  function renderTable() {
    if (state.status === 'success' && state.productCollection.length < 1) {
      return (
        <Alert className="text-center" variant="warning">
          <div>
            Não encontramos nenhum pedido no período selecionado.
          </div>
        </Alert>
      );
    }

    const collection = state.filteredCollection.length
      ? state.filteredCollection
      : state.productCollection;

    return (
      <Table className="border-top" striped hover responsive="md">
        <thead>
          <tr>
            <th>Código</th>
            <th>Produto</th>
            <th>Tipo</th>
            <th><span className="text-nowrap">Saldo inicial</span></th>
            <th><span className="text-nowrap">Produzindo</span></th>
            <th><span className="text-nowrap">Comprando</span></th>
            <th><span className="text-nowrap">Pedido/venda</span></th>
            <th><span className="text-nowrap">Insumo</span></th>
            <th><span className="text-nowrap">Estoque min.</span></th>
            <th><span className="text-nowrap">A produzir</span></th>
            <th><span className="text-nowrap">A comprar</span></th>
            <th><span className="text-nowrap">Saldo</span></th>
          </tr>
        </thead>
        <tbody className="border-top">
          {collection.map((item: CalculationItem, i: number) => {
            const isProduction = OmieService.isProduction(item.tipoItem);
            return (
              <tr key={ item.codigo_produto }>
                <td>{item.codigo}</td>
                <td>{item.descricao}</td>
                <td>{OmieService.PRODUCT_TYPE_MAP[item.tipoItem].name}</td>
                <td>{formatCell(item.estoque_atual)}</td>
                <td>{formatCell(item.qty_em_producao)}</td>
                <td>{formatCell(item.qty_em_pedidos_de_compra)}</td>
                <td>{formatCell(item.quantidade_vendida)}</td>
                <td>{formatCell(item.quantidade_vendida_como_insumo)}</td>
                <td>{formatCell(item.estoque_minimo)}</td>
                <td>{formatCell(isProduction ? item.compra_ou_producao : 0)}</td>
                <td>{formatCell(!isProduction ? item.compra_ou_producao : 0)}</td>
                <td>{formatCell(item.saldo)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  function onSearch(e: ChangeEvent<HTMLInputElement>) {
    if (!state.fuse) return;
    dispatch({
      type: 'setFilteredCollection',
      data: state.fuse.search(`'${e.target.value}`, {
        // @ts-ignore
        keys: ['descricao'],
      }).map((r) => r.item),
    });
  }

  return (
    <div>
      <div className="list-search-side d-flex align-items-start justify-content-start gap-2 mb-4">
        <InputGroup className="list-serach-input">
          <FormControl
            onChange={ onSearch }
            className="border-end-0"
            placeholder="Buscar produtos"
            aria-label="Buscar produtos"
          />
          <span className="btn bg-white border-color-input d-flex align-items-center border-start-0 cursor-default">
            <Icon name="search" className="fs-5" />
          </span>
        </InputGroup>
        <Button
          onClick={ dispatch.bind(null, {
            type: 'setFiltersVisibility',
            data: !state.isFiltersVisible,
          }) }
          variant="white"
          className="text-nowrap"
        >
          <span>Filtrar</span>
          {' '}
          <Icon name="arrow_drop_down" className="fs-5 align-middle" />
        </Button>
      </div>
      <POCalculationFilters visible={ state.isFiltersVisible } />
      <div>
        <LoadingWrapper loading={ state.status === 'pending' }>
          { renderTable() }
        </LoadingWrapper>
      </div>
      <div className="text-secondary">
        Cálculo:
        {' '}
        <strong>
          { state.initial_date instanceof Date ? state.initial_date.toLocaleDateString('pt-BR', {
            year: 'numeric', month: 'short', day: 'numeric',
          }) : null }
        </strong>
        {' '}
        até
        {' '}
        <strong>
          { state.final_date.toLocaleDateString('pt-BR', {
            year: 'numeric', month: 'short', day: 'numeric',
          }) }
        </strong>
        .
      </div>
      <hr />
      { state.productCollection.length ? (
        <div className="d-flex gap-2">
          <Button onClick={ submit }>
            <Icon name="inventory" className="fs-5 align-middle" />
            {' '}
            Gravar
          </Button>
          <Button variant="outline-primary" type="button" onClick={ onDownlodCsv }>
            <Icon name="download" className="fs-5 align-middle" />
            {' '}
            Baixar CSV
          </Button>
        </div>
      ) : null}
    </div>
  );
}
