import { DynamicTable, DynamicTableColumn } from 'components/DynamicTable';
import { Icon } from 'components/Icon';
import { ListTop } from 'components/Layout/ListTop';
import { Paginator } from 'components/Paginator';
import { useDelete } from 'hooks/useDelete';
import { useList } from 'hooks/useList';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import CustomerUserService, {
  CustomerUserModel,
} from 'services/CustomerUserService';
// import CustomerService, { CustomerUserModel } from 'services/CustomerService';
import NotificationService from 'services/NotificationService';
import { CustomerUsersForm } from './Form';

export function CustomersUserList() {
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' });
  const {
    status,
    collection,
    pagination,
    isFiltersVisible,
    fetchCollection,
    onSearch,
    onPaginate,
    onSort,
    linkFilter,
    toggleFilters,
  } = useList<CustomerUserModel>(
    CustomerUserService,
    searchParams,
    setSearchParams,
  );
  const {
    deletingModel, deletingStatus, confirmDelete, setDeletingModel,
  } = useDelete<CustomerUserModel>(CustomerUserService, afterDelete);
  const [formItem, setFormItem] = useState<CustomerUserModel | false>(false);

  const columns: DynamicTableColumn<CustomerUserModel>[] = [
    {
      label: 'COD',
      field: 'id',
      style: { width: '60px', textAlign: 'center' },
      thStyle: {
        width: '60px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      sortable: true,
    },
    {
      label: 'Nome',
      field: 'name',
      render: (customerUser) => customerUser.name,
      sortable: true,
    },
    {
      label: 'E-mail',
      field: 'trade_name',
      render: (customerUser) => customerUser.email,
      sortable: true,
    },
    {
      label: 'Tipo',
      field: 'users.name',
      render: (customerUser) => {
        if (customerUser.ind_admin) {
          return 'Master';
        }

        if (customerUser.roles?.length) {
          return customerUser.roles[0].title;
        }
        return null;
      },
      sortable: false,
    },
    {
      label: 'Editar',
      field: 'edit',
      style: { width: '1px' },
      render: (customerUser: CustomerUserModel) => (
        <Button
          size="sm"
          className="text-body"
          onClick={ setFormItem.bind(null, customerUser) }
          variant="link"
        >
          <Icon name="edit_note" className="fs-5 align-middle me-2" />
        </Button>
      ),
    },
    {
      label: 'Excluir',
      field: 'delete',
      style: { width: '1px' },
      render: (customerUser: CustomerUserModel) => (
        <Button
          size="sm"
          className="text-body"
          onClick={ setDeletingModel.bind(null, customerUser) }
          variant="link"
        >
          <Icon name="delete" className="fs-5 align-middle" />
        </Button>
      ),
    },
  ];

  function afterDelete() {
    setDeletingModel(false);
    fetchCollection();
  }

  function renderModalContent() {
    if (formItem === false) return null;

    return (
      <>
        <Modal.Header className="px-0">
          <Modal.Title as="h3">
            {formItem.id ? formItem.name : <strong>Novo usuário</strong>}
          </Modal.Title>
        </Modal.Header>
        <CustomerUsersForm
          inModal
          id={ formItem.id }
          afterSave={ () => {
            NotificationService.success(
              formItem.id ? 'usuário alterado.' : 'Novo usuário criado.',
              'Sucesso',
            );
            setFormItem(false);
            fetchCollection();
          } }
          onCancel={ setFormItem.bind(null, false) }
        />
      </>
    );
  }

  function renderDeleteModalContent() {
    if (!deletingModel) {
      return null;
    }

    return (
      <>
        <Modal.Body>
          Deseja excluir o usuário
          {' '}
          {deletingModel.name}
          ?
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button onClick={ confirmDelete } variant="danger">
              Excluir
            </Button>
            <Button
              onClick={ setDeletingModel.bind(null, false) }
              variant="white"
            >
              Fechar
            </Button>
          </div>
        </Modal.Footer>
      </>
    );
  }

  return (
    <div className="layout-wrapper layout-wrapper-list">
      <div className="mb-4">
        <ListTop
          q={ searchParams.get('q') || '' }
          onSearch={ onSearch }
          searchText="Buscar usuário"
          // toggleFilters={toggleFilters.bind(null, !isFiltersVisible)}
          custom={ (
            <Button
              onClick={ setFormItem.bind(
                null,
                CustomerUserService.getDefaultModel(),
              ) }
              variant="primary"
            >
              <Icon name="person_add" className="fs-5 align-middle me-2" />
              <span className="align-middle">Novo usuário</span>
            </Button>
          ) }
        />
      </div>

      <div className="pb-3">
        {/* <CustomersFilters
          isFiltersVisible={isFiltersVisible}
          linkFilter={linkFilter}
          // searchParams={searchParams}
        /> */}
      </div>

      <div className="mb-2">
        <DynamicTable
          loading={ status === 'pending' || deletingStatus === 'pending' }
          getRowKey={ (customer) => customer.id }
          columns={ columns }
          collection={ collection }
          searchParams={ searchParams }
          onSort={ onSort }
        />
      </div>

      <Paginator
        currentPage={ pagination.currentPage }
        offset={ pagination.offset }
        total={ pagination.total }
        onPaginate={ onPaginate }
      />

      <Modal
        dialogClassName="mw-1100p"
        contentClassName="p-3"
        show={ formItem !== false }
        onHide={ setFormItem.bind(null, false) }
      >
        {renderModalContent()}
      </Modal>

      <Modal
        show={ deletingModel !== false }
        onHide={ setDeletingModel.bind(null, false) }
      >
        {renderDeleteModalContent()}
      </Modal>
    </div>
  );
}
