import { ChangeEvent, ReactNode, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { StateLinkable } from 'types/Form';
// import Password from 'antd/lib/input/Password';
// import { StateLinkable } from '../Interfaces';

export function InputPassword({
  link,
  error,
  reveable,
  placeholder,
  toggleVariant = 'outline-secondary',
  inputProps = {},
}: {
  link: StateLinkable;
  error?: string | null;
  reveable?: boolean;
  placeholder: string;
  toggleVariant?: string;
  inputProps?: Record<string, any>;
}) {
  const [type, setType] = useState<'text' | 'password'>('password');
  let errorElement: null | JSX.Element = null;
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    link.onChange(e);
  }

  function toggleType() {
    setType(type === 'password' ? 'text' : 'password');
  }

  let inputElement = (
    <Form.Control
      type={ type }
      placeholder={ placeholder }
      onChange={ onChange }
      value={ link.value }
      name={ link.name }
      isInvalid={ !!error }
      { ...inputProps }
    />
  );

  if (error) {
    errorElement = <Form.Control.Feedback type="invalid">{ error }</Form.Control.Feedback>;
  }

  if (reveable) {
    inputElement = (
      <InputGroup>
        { inputElement }
        <Button onClick={ toggleType } variant={ toggleVariant } style={ { width: '40px' } }>
          <span className="material-icons-outlined small">
            {type === 'password' ? 'visibility_off' : 'visibility_on'}
          </span>
        </Button>
        {errorElement}
      </InputGroup>
    );
  }

  return (
    <div className="mb-15p">
      {inputElement}
    </div>
  );
}
