import { CalculationHistoryModel } from './CalculationHistoryService';
import { OmieProductType } from './OmieService';
import { Model, Rest } from './Rest';

export interface OrderHistoryItemModel extends Model {
  vendor_customer_id: number;
  vendor_order_id: number;
  vendor_order_number: number;
  vendor_product_id: number;
  name: string;
  customer_name: string;
  customer_trade_name?: string;
  feedstock_for?: number;
  ind_feedstock: boolean;
  sold_qty: number;
  sale_forecast: string;
  vendor_type: OmieProductType;
  calculation_history_id: number;
  calculation_history?: CalculationHistoryModel;
  customer_id: number;
}

export class OrderHistoryItemService extends Rest {
  resourceable_as = 'App\\Models\\OrderHistoryItem';

  getDefaultModel(merge?: Record<string, any>): OrderHistoryItemModel {
    return {
      vendor_customer_id: 0,
      vendor_order_id: 0,
      vendor_order_number: 0,
      vendor_product_id: 0,
      name: '',
      customer_name: '',
      customer_trade_name: '',
      ind_feedstock: false,
      sold_qty: 0,
      sale_forecast: '',
      vendor_type: '00',
      calculation_history_id: 0,
      customer_id: 0,
      ...merge,
    };
  }
}

export default new OrderHistoryItemService('OrderHistoryItemService', '/order-history-items');
