import { AxiosError, AxiosResponse } from 'axios';
import { OmieVendorType } from 'types/General';
import { Model, Rest } from './Rest';
import api from './api';

export interface ProductStructureItemModel extends Model {
  id?: string;
  name: string;
  type: OmieVendorType;
  quantity: 1;
  vendor_product_id: string;

}

export interface ProductStructureModel extends Model {
  id?: string;
  name: string;
  type: OmieVendorType;
  pending_sync: string | null;
  last_sync_date: string | null;
  items: ProductStructureItemModel[];
}

export class ProductStructureService extends Rest {
  resourceable_as = 'App\\Models\\ProductStructure';

  checkSyncStatus() : Promise<AxiosResponse<{has_pending_status: boolean}>> {
    return new Promise((resolve, reject) => {
      api
        .get(`${this.resoruceRoute}/check-sync-status`)
        .then((
          response: AxiosResponse<{has_pending_status: boolean}>,
        ) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  clear() : Promise<AxiosResponse<true>> {
    return new Promise((resolve, reject) => {
      api
        .delete(`${this.resoruceRoute}/clear`)
        .then((
          response: AxiosResponse<true>,
        ) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  sync(id: string) : Promise<AxiosResponse<ProductStructureModel>> {
    return new Promise((resolve, reject) => {
      api
        .get(`${this.resoruceRoute}/${id}/sync`)
        .then((
          response: AxiosResponse<ProductStructureModel>,
        ) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
}

export default new ProductStructureService(
  'ProductStructureService',
  '/product-structures',
);
