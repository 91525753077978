import { CustomerModel } from './CustomerService';
import { Model, Rest } from './Rest';

export interface CustomerUserModel extends Model {
  id?: number | string;
  name: string;
  email: string;
  ind_admin: boolean;
  updated_at: string;
  created_at: string;
  customer_id: number;
  customer?: CustomerModel;
  roles?: {title: string, name: string}[];
}

export class CustomerUserService extends Rest {
  resourceable_as = 'App\\Models\\CustomerUser';

  getDefaultModel(merge?: Record<string, any>): CustomerUserModel {
    return {
      name: '',
      email: '',
      ind_admin: false,
      updated_at: '',
      created_at: '',
      customer_id: 0,
      ...merge,
    };
  }
}

export default new CustomerUserService(
  'CustomerUserService',
  '/customer-users',
);
