import { ChangeEvent, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { FilterLinkable } from 'types/List';

export default function SelectFilter({
  placeholder,
  link,
  suffix,
  prefix,
  options,
  hideEmptyOption = false,
  getOptionValue = (item: any) => item.id,
  getOptionLabel = (item: any) => item.name,
} : {
    placeholder: string,
    link: FilterLinkable,
    options: Record<string, any>[],
    hideEmptyOption?: boolean,
    getOptionValue?: (item: any) => string,
    getOptionLabel?: (item: any) => string,
    suffix?: ReactNode,
    prefix?: ReactNode,
}) {
  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    link.onChange(e);
  }

  return (

    <div className="mb-15p">
      <Form.Select
        onChange={ onChange }
        value={ link.value }
        name={ link.name }
      >
        { hideEmptyOption ? null : <option value="">{ placeholder }</option> }
        { options.map((item) => (
          <option key={ getOptionValue(item) } value={ getOptionValue(item) }>
            { getOptionLabel(item) }
          </option>
        )) }
      </Form.Select>
    </div>
  );
}
