/* eslint-disable */
export default function MysqlToBRDateTime(
  strDate: any,
  includeTime = false,
  separator = ' ',
) {
  const d = new Date(strDate);
  const formatedDate = `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
  const formatedTime = includeTime
    ? `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
    : '';
  return `${formatedDate}${separator}${formatedTime}`;
}
