import { Form } from 'components/Form/Form';
import { InputSelect } from 'components/Form/InputSelect';
import { InputText } from 'components/Form/InputText';
import { useForm } from 'hooks/useForm';
import { Fragment } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { routes } from 'routing/routes';
import CustomerUserService, { CustomerUserModel } from 'services/CustomerUserService';

export function CustomerUsersForm({
  id,
  inModal = false,
  afterSave,
  onCancel,
}: {
  id?: number | string;
  inModal?: boolean;
  afterSave: () => void;
  onCancel: () => void;
}) {
  const {
    state, status, relations, getError, link, submit,
  } = useForm<CustomerUserModel>(
    CustomerUserService,
    CustomerUserService.getDefaultModel(),
    { afterSave, id, fetchRelations: true },
  );

  function beforeSubmit(e: SubmitEvent) {
    e.preventDefault();
    const data = { ...state, document_type: 'CNPJ', return_url: `${window.location.origin.replace(/admin\./ig, '')}${routes.passwordReset.path}` };
    submit(data);
  }

  let FieldsWrapper: any = Fragment;
  let ButtonsWrapper: any = Fragment;

  if (inModal) {
    FieldsWrapper = Modal.Body;
    ButtonsWrapper = Modal.Footer;
  }

  return (
    <Form onSubmit={ beforeSubmit } loading={ status === 'pending' }>
      <FieldsWrapper className="px-0">
        <Row>
          <Col md="12">
            <InputText
              placeholder="Nome completo"
              link={ link('name') }
              error={ getError('name') }
            />
          </Col>
        </Row>
        <Row>
          <Col md="9">
            <InputText
              placeholder="E-mail"
              link={ link('email') }
              error={ getError('email') }
            />
          </Col>
          <Col md="3">
            <InputSelect
              placeholder="Tipo"
              link={ link('role_id') }
              error={ getError('role_id') }
              options={ relations.roles || [] }
              getOptionLabel={ (item) => item.title }
            />
          </Col>
        </Row>
      </FieldsWrapper>
      <ButtonsWrapper className="px-0">
        <div className="d-flex gap-3 w-100">
          <Button type="submit">Salvar</Button>
          <Button onClick={ onCancel } variant="white">
            Cancelar
          </Button>
        </div>
      </ButtonsWrapper>
    </Form>
  );
}
