/*eslint-disable */
import { AxiosResponse, AxiosError } from 'axios';
import api from './api';

export interface Model {
  [key: string]: any;
}

export interface RestInterface {
  find: (id: number | string, qs?: Record<string, any>) => Promise<any>;
  get: (qs: Record<string, any>) => Promise<any>;
  store: (data: FormData | Model) => Promise<any>;
  update: (data: FormData | Model, partial?: boolean) => Promise<any>;
  save: (data: FormData | Model) => Promise<any>;
  delete: (id: number | string) => Promise<any>;
  fetchRelations: (origin: 'index' | 'form') => Promise<AxiosResponse<{[key: string]: unknown[]}>>;
}

export class Rest implements RestInterface {
  protected serviceName: string;

  protected resoruceRoute: string;

  protected resourceable_as = '';

  constructor(serviceName: string, resoruceRoute: string) {
    this.serviceName = serviceName;
    this.resoruceRoute = resoruceRoute;
  }

  find(id: number | string, qs?: Record<string, any>): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .get(`${this.resoruceRoute}/${id}`, {
          params: qs,
        })
        .then((response: AxiosResponse) => {
          // EventManager.notify(`${this.serviceName}.find`, response);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          // EventManager.notify(`${this.serviceName}.find.error`, error);
          reject(error);
        });
    });
  }

  get(qs: any): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .get(this.resoruceRoute, {
          params: qs,
        })
        .then((response: AxiosResponse) => {
          // EventManager.notify(`${this.serviceName}.get`, response);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          // EventManager.notify(`${this.serviceName}.get.error`, error);
          reject(error);
        });
    });
  }

  store(data: FormData | Model): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .post(this.resoruceRoute, data)
        .then((response: AxiosResponse) => {
          // EventManager.notify(`${this.serviceName}.store`, response);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          // EventManager.notify(`${this.serviceName}.store.error`, error);
          reject(error);
        });
    });
  }

  update(data: FormData | Model, partial = false): Promise<any> {
    let id: any;
    const _method = partial ? 'PATCH' : 'PUT';
    if (data instanceof FormData) {
      data.append('_method', _method);
      id = data.get('id');
    } else {
      data._method = _method;
      id = data.id;
    }

    return new Promise((resolve, reject) => {
      api
        .post(`${this.resoruceRoute}/${id}`, data)
        .then((response: AxiosResponse) => {
          // EventManager.notify(`${this.serviceName}.update`, response);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          // EventManager.notify(`${this.serviceName}.update.error`, error);
          reject(error);
        });
    });
  }

  save(data: FormData | Model): Promise<any> {
    const id = data instanceof FormData ? data.get('id') : data.id;
    if (!id) {
      return this.store(data);
    }
    return this.update(data);
  }

  delete(id: string | number): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .delete(`${this.resoruceRoute}/${id}`, {
          data: { _method: 'DELETE' },
        })
        .then((response: AxiosResponse) => {
          // EventManager.notify(`${this.serviceName}.delete`, response);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          // EventManager.notify(`${this.serviceName}.delete.error`, error);
          reject(error);
        });
    });
  }

  fetchRelations(origin: 'index' | 'form' = 'form'): Promise<AxiosResponse<{[key: string]: any[]}>> {
    return new Promise((resolve, reject) => {
      api
        .get(`${this.resoruceRoute}/get-form-relations`, {
          params: {origin},
        })
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  getDefaultModel(merge?: Record<string, any>): Model {
    return {
      ...merge,
    };
  }
}
