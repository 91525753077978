import { AxiosError, AxiosResponse } from 'axios';
import GetFlattened from 'helpers/GetFlattened';
import SetFlattened from 'helpers/SetFlattened';
import { useEffect, useState } from 'react';
import NotificationService from 'services/NotificationService';
import { RestInterface } from 'services/Rest';
import { ErrorsType, StateLinkable } from 'types/Form';
import { statuses } from 'types/General';

type useFormOptions<M extends {[key: string]: unknown}> = {
  id?: string | number;
  fetchRelations?: boolean;
  afterSave?: (res: AxiosResponse) => void;
  onFind?: (data: M) => M;
};

export function useForm<M extends {[key: string]: unknown}>(
  service: RestInterface,
  initialState: M,
  options: useFormOptions<M> = {},
) : {
    status: statuses,
    state: M,
    relations: Record<string, any[]>,
    link: ((field: string) => StateLinkable),
    getError: (field: string) => string | null,
    submit: (data: FormData | M) => void,
    setStatus: (data: statuses) => void,
    setState: (data: M) => void,
    setErrors: (data: ErrorsType) => void,
} {
  const { id } = options;
  const [status, setStatus] = useState<statuses>('idle');
  const [state, setState] = useState<M>(initialState);
  const [relations, setRelations] = useState<Record<string, unknown[]>>({});
  const [errors, setErrors] = useState<ErrorsType>({});

  function getError(field: string) {
    if (errors[field] !== undefined) {
      for (const prop in errors[field]) {
        if (Object.prototype.hasOwnProperty.call(errors[field], prop)) {
          return errors[field][prop];
        }
      }
    }
    return null;
  }

  function changeStateField(field: string, value: any) {
    setState(
      SetFlattened(field, value, { ...state }),
    );
  }

  function link(field: string) {
    return {
      name: field,
      value: GetFlattened(field, state, ''),
      onChange: (e: any) => {
        changeStateField(field, e.target.value);
      },
    };
  }

  function submit(data: M | FormData) {
    setStatus('pending');
    setErrors({});
    service.save(data)
      .then((res: AxiosResponse) => {
        setStatus('success');
        if (options?.afterSave) {
          options.afterSave(res);
        }
      })
      .catch((error: AxiosError) => {
        setStatus('error');
        if (!error.response) {
          return;
        }
        switch (error.response.status) {
          case 422:
            NotificationService.danger('Ops, verifique todos os campos.', 'Erro de validação');
            setErrors({ hasErrors: true, ...error.response.data.error_data });
            break;
          default:
            break;
        }
      });
  }

  useEffect(() => {
    const calls: Array<Promise<any> | false> = [false, false];
    if (id) {
      calls[0] = service.find(id);
    }
    if (options.fetchRelations) {
      calls[1] = service.fetchRelations('form');
    }

    setStatus('pending');
    Promise.all(calls)
      .then((results) => {
        setStatus('idle');
        if (results[0] !== false) {
          let { data } = results[0].data;
          if (options.onFind) {
            data = options.onFind({ ...data });
          }
          setState(data);
        }
        if (results[1] !== false) {
          setRelations(results[1].data);
        }
      })
      .catch(() => {
        setStatus('error');
        NotificationService.danger('Ops, tivemos um erro interno.', 'Erro interno');
      });
  }, [service, id]);

  return {
    status,
    state,
    relations,
    link,
    getError,
    submit,
    setStatus,
    setState,
    setErrors,
  };
}
