import { AxiosError, AxiosResponse } from 'axios';
// import { useNavigate } from "react-router-dom";
import api from 'services/api';
import NotificationService from 'services/NotificationService';
import { BrowserHistory } from 'history';
import { routes } from './routes';

export function AuthMiddleware(history: BrowserHistory) {
  const allowedRoutes = [
    routes.login.path,
    routes.passwordRecovery.path,
    routes.passwordReset.path,
  ];

  // api.interceptors.request.use(
  //   (config) => {
  //     // eslint-disable-next-line no-param-reassign
  //     config.params = { ...config.params, XDEBUG_TRIGGER: 1 };
  //     return config;
  //   },
  //   (error) => Promise.reject(error),
  // );

  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.status) {
          case 400:
          case 401:
            if (allowedRoutes.indexOf(window.location.pathname) < 0) {
              history.push(routes.login.path);
            }
            break;
          case 403:
            NotificationService.warning(
              'Ops, parece que você não tem permissão para esta ação. 😕',
              'Autorização negada',
            );
            break;
          case 500:
            if (error.response?.data?.error_data?.non_field) {
              NotificationService.danger(error.response.data.error_data.non_field, 'Erro');
            } else {
              NotificationService.danger(
                'Ops, tivemos um problema interno e já estamos trabalhando pra resolver. 😓',
                'Erro no servidor',
              );
            }
            break;
          default:
            break;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMlHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      return Promise.reject(error);
    },
  );
}
