import { ChangeEvent, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { StateLinkable } from 'types/Form';

export function InputSelect({
  link,
  error,
  help = null,
  options,
  suffix,
  prefix,
  placeholder,
  getOptionValue = (item: any) => item.id,
  getOptionLabel = (item: any) => item.name,
  hookOnChange,
  inputProps = {},
} : {
        link: StateLinkable,
        error: string | null,
        help?: string | null,
        suffix?: ReactNode,
        prefix?: ReactNode,
        options: Record<string, any>[],
        getOptionValue?: (item: any) => string,
        getOptionLabel?: (item: any) => string,
        hookOnChange?: (e: ChangeEvent<HTMLSelectElement>) => void,
        mask?: (value: unknown) => unknown,
        placeholder: string,
        inputProps?: Record<string, any>,
    }) {
  let errorElement: ReactNode = null;
  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    if (hookOnChange) {
      hookOnChange(e);
    }
    link.onChange(e);
  }

  if (error) {
    errorElement = (
      <Form.Control.Feedback type="invalid">
        { error }
      </Form.Control.Feedback>
    );
  }

  return (
    <div className="mb-15p">
      <Form.Select
        onChange={ onChange }
        value={ link.value }
        name={ link.name }
        isInvalid={ !!error }
        { ...inputProps }
      >
        <option value="">{ placeholder }</option>
        { options.map((item) => (
          <option key={ getOptionValue(item) } value={ getOptionValue(item) }>
            { getOptionLabel(item) }
          </option>
        )) }
      </Form.Select>
      { help ? <small className="fs-12p text-secondary">{ help }</small> : null }
      { errorElement }
    </div>
  );
}
