export default function ObjectFind(list: {[key: string]: any}[], prop: string, value: any) : {
    index: number,
    object: Record<string, unknown> | null
} {
  for (let i = 0, len = list.length; i < len; i += 1) {
    // eslint-disable-next-line eqeqeq
    if (list[i][prop] == value) {
      return {
        index: i,
        object: list[i],
      };
    }
  }
  return { index: -1, object: null };
}

// export default function ObjectFind(
//     list: {[key: string]: any}[] | Record<string, any>,
//     key: string,
//     value: any
// ) : {
//     index: number | string,
//     object: Object | null
// } {
//     if (Array.isArray(list)) {
//         for (let i = 0, len = list.length; i < len; i += 1) {
//             // eslint-disable-next-line eqeqeq
//             if (list[i][key] == value) {
//                 return {
//                     index: i,
//                     object: list[i]
//                 };
//             }
//         }
//     } else if (typeof list === 'object') {
//         for (const i in list) {
//             // eslint-disable-next-line eqeqeq
//             if (list[i][key] == value) {
//                 return {
//                     index: i,
//                     object: list[i]
//                 };
//             }
//         }
//     }
//     return { index: -1, object: null };
// }
