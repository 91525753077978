import { ReactComponent as CalculateIcon } from 'assets/images/calculate-icon.svg';
import { Icon } from 'components/Icon';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routes } from 'routing/routes';
import { usePOCalculation } from './Context';

export function POCalculationHeader() {
  const { state, dispatch } = usePOCalculation();
  return (
    <div className="d-flex align-items-start justify-content-between">
      <div>
        <h1 className="h3 text-uppercase">
          <strong>Planejamento</strong>
        </h1>
      </div>
      {state.has_pending_status ? (
        <div>
          <Alert variant="warning">A sincronização das malhas ainda está em andamento.</Alert>
        </div>
      ) : null}
      <div className="d-flex gap-2">
        <Link className="btn btn-white d-flex align-items-center gap-1" to={ routes.omieCalculationHistory.path }>
          <Icon name="history" className="fs-4 align-middle" />
          {' '}
          Histórico de Operações
        </Link>
        <Button
          className="d-flex align-items-center gap-1"
          onClick={ dispatch.bind(null, {
            type: 'setModalVisibility',
            data: true,
          }) }
        >
          <CalculateIcon />
          {' '}
          Calcular necessidade (OP + OC)
        </Button>
      </div>
    </div>
  );
}
