import { Config } from 'configs';
import {
  FormEvent, useEffect, useState,
} from 'react';
import {
  Button, Form, ListGroup, ListGroupItem, Modal, Spinner,
} from 'react-bootstrap';
import { CustomerModel } from 'services/CustomerService';
import NotificationService from 'services/NotificationService';
import OmieService, { OmieCustomer } from 'services/OmieService';

export function SuppliersConfiguration(
  {
    customer,
    submit,
  } : {
     customer: CustomerModel,
     submit: (data: any) => void
  },
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [OCDefaultSupplier, setOCDefaultSupplier] = useState<number>(0);
  const [suppliers, setSuppliers] = useState<OmieCustomer[] | null>(null);

  useEffect(() => {
    setOCDefaultSupplier(parseInt(customer.settings.OC_default_supplier, 10));
  }, [customer.settings.OC_default_supplier]);

  function openModal() {
    setModalVisibility(true);
    setLoading(true);
    OmieService.listarFornecedores()
      .then((res) => {
        res.data.sort(
          (a: OmieCustomer, b: OmieCustomer) => a.nome_fantasia.localeCompare(b.nome_fantasia),
        );
        setSuppliers(res.data);
      })
      .catch((err) => {
        NotificationService.danger('Ops, não conseguimos buscar os fornecedores.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function closeModal() {
    setModalVisibility(false);
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submit({ OC_default_supplier: OCDefaultSupplier });
  }

  function onSelect(value: number) {
    setOCDefaultSupplier(value);
  }

  function renderForm() {
    if (loading) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="grow" />
        </div>
      );
    }
    return (
      <Form onSubmit={ onSubmit }>
        <ListGroup
          className="custom-scrollbar"
          style={ {
            maxHeight: '80vh',
            overflow: 'auto',
            paddingRight: '5px',
            paddingBottom: '30px',
          } }
        >
          { suppliers && suppliers.map((supplier) => {
            const checked = supplier.codigo_cliente_omie === OCDefaultSupplier;
            return (
              <ListGroupItem
                variant={ checked ? 'primary' : '' }
                className="cursor-pointer"
                onClick={ onSelect.bind(null, supplier.codigo_cliente_omie) }
                key={ supplier.codigo_cliente_omie }
              >
                <Form.Check
                  label={ supplier.nome_fantasia }
                  type="radio"
                  checked={ checked }
                  readOnly
                />
              </ListGroupItem>
            );
          }) }

        </ListGroup>
        <hr />
        <div className="d-flex gap-3 justify-content-end">
          <Button
            onClick={ closeModal }
            variant="white"
          >
            Fechar
          </Button>
          <Button
            onClick={ closeModal }
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </Form>
    );
  }

  return (
    <>
      <div className="text-uppercase mb-2">
        <strong>Selecionar fornecedor padrão</strong>
      </div>
      <div>
        <p>
          Selecione um fornecedor padrão para associar os
          Pedidos de Compra quando eles forem gerados pelo
          {' '}
          { Config.app.name }
        </p>
        <Button
          onClick={ openModal }
          className="text-nowrap"
        >
          <span>Selecionar</span>
        </Button>
      </div>

      <Modal show={ isModalVisible } onHide={ closeModal }>
        <Modal.Header>
          <Modal.Title>Selecione um fornecedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { renderForm() }
        </Modal.Body>
      </Modal>
    </>
  );
}
