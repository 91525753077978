import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactNode, useState } from 'react';
import { Form } from 'react-bootstrap';
import { StateLinkable } from 'types/Form';
import { ptBR } from 'date-fns/locale';

export function InputDate({
  link,
  error = null,
  help = null,
  placeholder = '',
}: {
  link: StateLinkable;
  error?: string | null;
  help?: string | null;
  placeholder?: string;
}) {
  const [open, setOpen] = useState<boolean>(false);
  let label: ReactNode = null;
  let errorElement: ReactNode = null;
  function onChange(
    date: unknown,
    keyboardInputValue?: string | undefined,
  ): void {
    link.onChange({ target: { value: date } });
    setOpen(false);
  }

  if (error) {
    errorElement = (
      <Form.Control.Feedback className="d-block" type="invalid">{error}</Form.Control.Feedback>
    );
  }
  if (placeholder) {
    label = <label className="form-label">{placeholder}</label>;
  }

  return (
    <div className="mb-15p">
      <LocalizationProvider dateAdapter={ AdapterDateFns } locale={ ptBR }>
        <DesktopDatePicker
          label={ placeholder }
          inputFormat="dd/MM/yyyy"
          value={ link.value }
          onChange={ onChange }
          open={ open }
          renderInput={ ({ inputRef, inputProps }) => {
            const toggle = setOpen.bind(null, !open);
            return (
              <div>
                {label}
                <div className="input-group border">
                  <button
                    type="button"
                    onClick={ toggle }
                    className="btn bg-white d-flex align-items-center border-start-0 cursor-default"
                  >
                    <span className="material-icons material-icons-outlined fs-5">
                      event
                    </span>
                  </button>
                  <input
                    ref={ inputRef }
                    { ...inputProps }
                    className="form-control border-0"
                  />
                  <button
                    type="button"
                    onClick={ toggle }
                    className="btn bg-white d-flex align-items-center border-start-0 cursor-default"
                  >
                    <span className="material-icons material-icons-outlined fs-5">
                      arrow_drop_down
                    </span>
                  </button>
                </div>
              </div>
            );
          } }
        />
      </LocalizationProvider>
      {help ? <small className="fs-12p text-secondary">{help}</small> : null}
      {errorElement}
    </div>
  );
}
