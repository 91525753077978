import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import NotificationService from 'services/NotificationService';
import { RestInterface } from 'services/Rest';
import { statuses } from 'types/General';

export function useDelete<M extends {id?: number | string}>(
  service: RestInterface,
  afterDelete?: (res: AxiosResponse) => void,
): {
  deletingStatus: statuses;
  deletingModel: M | false;
  setDeletingModel: (model: M | false) => void;
  confirmDelete: () => void;
} {
  const [deletingStatus, setDeletingStatus] = useState<statuses>('idle');
  const [deletingModel, setDeletingModel] = useState<M | false>(false);

  function confirmDelete() {
    if (!deletingModel) return;

    setDeletingStatus('pending');
    service
      .delete(deletingModel.id || 0)
      .then((res: AxiosResponse) => {
        setDeletingStatus('success');
        if (afterDelete) {
          afterDelete(res);
        }
      })
      .catch((error: AxiosError) => {
        setDeletingStatus('error');
        if (!error.response) {
          return;
        }
        switch (error.response.status) {
          case 422:
            if (error.response.data.error_data.non_field) {
              NotificationService.danger(
                error.response.data.error_data.non_field[0],
                'Erro de validação',
              );
            }
            break;
          default:
            NotificationService.danger(
              'Ops, não conseguimos realizar esta ação.',
              'Erro',
            );
            break;
        }
      });
  }

  return {
    deletingModel,
    deletingStatus,
    setDeletingModel,
    confirmDelete,
  };
}
