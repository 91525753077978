import { AxiosError, AxiosResponse } from 'axios';
import { InputPassword } from 'components/Form/InputPassword';
import { Icon } from 'components/Icon';
import { LoadingWrapper } from 'components/LoadingWrapper';
import { useForm } from 'hooks/useForm';
import { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button, Form, Modal,
} from 'react-bootstrap';
import AuthService from 'services/AuthService';
import CustomerService from 'services/CustomerService';
import NotificationService from 'services/NotificationService';
import { CustomersUserList } from 'views/customerUser/List';
import { CategoriesConfiguration } from './CategoriesConfiguration';
import { ProductStructureSyncConfiguration } from './ProductStructureSyncConfiguration';
import { StepsConfiguration } from './StepsConfiguration';
import { SuppliersConfiguration } from './SuppliersConfiguration';

export function ConfigurationDashboard() {
  const [hasPermissionToSeeUsers, setHasPermissionToSeeUsers] = useState<boolean>(false);
  const [isClearDbModalVisible, setClearDbModalVisibility] = useState<boolean>(false);
  const {
    state, status, link, setStatus, setState, setErrors, getError,
  } = useForm(
    CustomerService,
    CustomerService.getDefaultModel(),
  );

  const fetchMe = useCallback(() => {
    setStatus('pending');
    AuthService.me().then((res) => {
      setStatus('success');
      if (res.data.user.customer) {
        setState(res.data.user.customer);
      }
      let isAllowedToSeeUsers = false;
      if (res.data.user.ind_admin) {
        isAllowedToSeeUsers = true;
      }
      res.data.user.roles?.forEach((role) => {
        if (role.name === 'admin') {
          isAllowedToSeeUsers = true;
        }
      });
      setHasPermissionToSeeUsers(isAllowedToSeeUsers);
    }).catch((err) => {
      setStatus('error');
    });
  }, [setState, setStatus]);

  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  const consideStockLink = link('settings.ind_use_stock');

  function confirmDelete() {
    setStatus('pending');
    CustomerService.clearDatabase()
      .then((res: AxiosResponse) => {
        NotificationService.success('Banco limpo com sucesso.');
        setStatus('success');
      })
      .catch((err: AxiosError) => {
        setStatus('error');
      })
      .finally(() => {
        setClearDbModalVisibility(false);
      });
  }

  function openModal() {
    setClearDbModalVisibility(true);
  }

  function closeModal() {
    setClearDbModalVisibility(false);
  }

  function submit(data: any) {
    setStatus('pending');
    CustomerService.updateSettings(data)
      .then((res: AxiosResponse) => {
        NotificationService.success('Configurações atualizadas com sucesso.');
        setStatus('success');
        setErrors({ hasErrors: false, error_data: {} });
      })
      .catch((err: AxiosError) => {
        setStatus('error');
        if (!err.response) {
          return;
        }

        switch (err.response.status) {
          case 422:
            if (err.response.data.error_data.non_field) {
              NotificationService.danger(err.response?.data.error_data.non_field, 'Erro');
            } else {
              setErrors({ hasErrors: true, ...err.response.data.error_data });
            }
            break;
          default:
            break;
        }
      });
  }

  function onkeysSave() {
    submit({
      app_key: state.settings.app_key,
      app_secret: state.settings.app_secret,
    });
  }

  function onChangeConsiderStock() {
    const indUseStock = !state.settings.ind_use_stock;
    consideStockLink.onChange({
      target: { value: indUseStock },
    });
    submit({ ind_use_stock: indUseStock });
  }

  function renderTokens() {
    return (
      <>
        <div className="text-uppercase mb-2">
          <strong>Tokens</strong>
        </div>
        <div>
          <p>
            Para integrar o PCPSmart com a sua conta do Omie, informe seus tokens de integração.
            <br />
            <a
              href="https://ajuda.omie.com.br/pt-BR/articles/499061-obtendo-a-chave-de-acesso-para-integracoes-de-api"
              target="_blank"
              rel="noopener noreferrer"
            >
              Como obter tokens de acesso
            </a>
          </p>

          <InputPassword error={ getError('app_key') } link={ link('settings.app_key') } reveable placeholder="APP_KEY" />
          <InputPassword
            reveable
            error={ getError('app_secret') }
            link={ link('settings.app_secret') }
            placeholder="APP_SECRET"
          />
        </div>
        <Button onClick={ onkeysSave }>Salvar</Button>
      </>
    );
  }

  function renderConsiderStock() {
    return (
      <>
        <div className="text-uppercase mb-2">
          <strong>Saldo</strong>
        </div>
        <div className="d-flex justify-content-between align-items-end gap-3">
          <div>
            Ative esta opção para considerar
            o &rdquo;Saldo Anterior&rdquo; e o &rdquo;Estoque Mínimo&rdquo;
            em suas operações.
          </div>
          <Form.Check
            onChange={ onChangeConsiderStock }
            checked={ state.settings.ind_use_stock }
            type="switch"
            id="ind_use_stock-switch"
          />
        </div>
      </>
    );
  }

  function renderClearDatabase() {
    return (
      <>
        <div className="text-uppercase mb-2">
          <strong>Limpar banco de dados</strong>
        </div>
        <div>
          <p>
            Ao clicar em limpar todos os dados do histórico
            de operações e de pedidos serão perdidos
            definitivamente.
          </p>
          <Button
            onClick={ openModal }
            variant="white"
            className="text-nowrap"
          >
            <Icon name="delete" className="fs-5 align-middle me-2" />
            <span>Limpar</span>
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className="d-flex gap-4">
      <div className="pb-5" style={ { maxWidth: '340px' } }>
        <LoadingWrapper loading={ status === 'pending' }>
          { renderTokens() }

          <hr className="my-5" />

          <StepsConfiguration customer={ state } submit={ submit } />

          <hr className="my-5" />

          <CategoriesConfiguration customer={ state } submit={ submit } />

          <hr className="my-5" />

          <SuppliersConfiguration customer={ state } submit={ submit } />

          <hr className="my-5" />

          <ProductStructureSyncConfiguration fetchMe={ fetchMe } customer={ state } />

          <hr className="my-5" />

          { renderConsiderStock() }

          <hr className="my-5" />

          { renderClearDatabase() }

        </LoadingWrapper>
      </div>

      <div className="vr" />

      <div className="flex-grow-1 pb-5">
        { hasPermissionToSeeUsers ? <CustomersUserList /> : null }
      </div>

      <Modal show={ isClearDbModalVisible } onHide={ closeModal }>
        <Modal.Body>
          <Alert variant="danger">
            <div className="mb-2">Deseja limpar todo o histórico de consultas?</div>
            <strong>ATENÇÃO ESTA AÇÃO É IRREVERSÍVEL</strong>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button onClick={ confirmDelete } variant="danger">
              Confirmar
            </Button>
            <Button
              onClick={ closeModal }
              variant="white"
            >
              Fechar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
