import { ReactNode } from 'react';

export function PageTitleRow({ title, children } : {title: ReactNode, children: ReactNode}) {
  return (
    <div className="d-flex justify-content-between">
      <div>
        <h1 className="h3 text-uppercase">
          <strong>{ title }</strong>
        </h1>
      </div>
      <div>
        { children }
      </div>
    </div>
  );
}
