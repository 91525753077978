export const OmieVendorType = {
  MERCADORIA_PARA_REVENDA: '00',
  MATERIA_PRIMA: '01',
  EMBALAGEM: '02',
  PRODUTO_EM_PROCESSO: '03',
  PRODUTO_ACABADO: '04',
  SUBPRODUTO: '05',
  PRODUTO_INTERMEDIARIO: '06',
  MATERIAL_DE_USO_E_CONSUMO: '07',
  ATIVO_IMOBILIZADO: '08',
  SERVICOS: '09',
  OUTROS_INSUMOS: '10',
  OUTRAS: '99',
} as const;

export const OmieVendorTypeName = {
  '00': 'Mercadoria para revenda',
  '01': 'Materia prima',
  '02': 'Embalagem',
  '03': 'Produto em processo',
  '04': 'Produto acabado',
  '05': 'Subproduto',
  '06': 'Produto intermediario',
  '07': 'Material de uso e consumo',
  '08': 'Ativo imobilizado',
  '09': 'Servicos',
  10: 'Outros insumos',
  99: 'Outras',
} as const;
