import { ChangeEvent, useEffect, useState } from 'react';
import {
  Col, Collapse, Form, FormControl, Row,
} from 'react-bootstrap';
import { OmieService } from 'services/OmieService';
import { usePOCalculation } from './Context';

export function POCalculationFilters({ visible }: { visible: boolean }) {
  const { state, dispatch } = usePOCalculation();

  function onChangeTextFilter(field: string, e: ChangeEvent<HTMLInputElement>) {
    if (!state.fuse) return;
    dispatch({
      type: 'setFilteredCollection',
      data: state.fuse.search(`'${e.target.value}`, {
        // @ts-ignore
        keys: [field],
      }).map((r) => r.item),
    });
  }

  function onChangeSelectFilter(field: string, e: ChangeEvent<HTMLSelectElement>) {
    if (!state.fuse) return;
    dispatch({
      type: 'setFilteredCollection',
      data: state.fuse.search(`=${e.target.value}`, {
        // @ts-ignore
        keys: [field],
      }).map((r) => r.item),
    });
  }

  return (
    <Collapse in={ visible }>
      <div>
        <Row className="mb-3">
          <Col md="3">
            <FormControl type="text" placeholder="Código" onChange={ onChangeTextFilter.bind(null, 'codigo_produto') } />
          </Col>
          <Col md="3">
            <Form.Select placeholder="Tipo" onChange={ onChangeSelectFilter.bind(null, 'tipoItem') }>
              <option>Tipo</option>
              { OmieService.PRODUCT_TYPE_OPTIONS.map((pt) => (
                <option key={ pt.id } value={ pt.id }>{ pt.name }</option>
              )) }
            </Form.Select>
          </Col>
          <Col md="3">
            <FormControl type="text" placeholder="Saldo inicial" onChange={ onChangeTextFilter.bind(null, 'estoque_atual') } />
          </Col>
          <Col md="3">
            <FormControl type="text" placeholder="Produzindo" onChange={ onChangeTextFilter.bind(null, 'qty_em_producao') } />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md="3">
            <FormControl type="text" placeholder="Comprando" onChange={ onChangeTextFilter.bind(null, 'qty_em_pedidos_de_compra') } />
          </Col>
          <Col md="3">
            <FormControl type="text" placeholder="Pedido/venda" onChange={ onChangeTextFilter.bind(null, 'quantidade_vendida') } />
          </Col>
          <Col md="3">
            <FormControl type="text" placeholder="Insumo" onChange={ onChangeTextFilter.bind(null, 'quantidade_vendida_como_insumo') } />
          </Col>
          <Col md="3">
            <FormControl type="text" placeholder="Estoque min." onChange={ onChangeTextFilter.bind(null, 'estoque_minimo') } />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md="3">
            <FormControl type="text" placeholder="Saldo" onChange={ onChangeTextFilter.bind(null, 'balance') } />
          </Col>
        </Row>
      </div>
    </Collapse>
  );
}
