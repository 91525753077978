import { ChangeEvent, ReactNode, useState } from 'react';
// import LogoSlogan from 'assets/img/logo-slogan.svg';
import { Form } from 'components/Form/Form';
import { InputText } from 'components/Form/InputText';
import { InputPassword } from 'components/Form/InputPassword';
import { Alert, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'routing/routes';
import { PCPSmart } from 'assets/PCPSmart';
import { Config } from 'configs';
import MaskPhone from 'helpers/MaskPhone';
import AuthService from 'services/AuthService';
import { Icon } from 'components/Icon';
import { Contact } from './Contact';
// import { Route } from 'helpers/Route';
// import { Redirect } from 'helpers/Redirect';

export function Login() {
  let ErrorElement: ReactNode = null;
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigation = useNavigate();

  function change(field: string, e: ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [field]: e.target.value });
  }

  function getError(field: string) {
    if (errors[field] !== undefined) {
      for (const prop in errors[field]) {
        if (Object.prototype.hasOwnProperty.call(errors[field], prop)) {
          return errors[field][prop];
        }
      }
    }
    return null;
  }

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();
    setLoading(true);
    AuthService.authenticate(data).then((resData: any) => {
      setErrors({});
      navigation(resData.redirect);
    }).catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 422:
            setErrors(err.response.data.error_data);
            break;
          default:
            break;
        }
      }
      setLoading(false);
    });
  }

  if (errors.non_field) {
    ErrorElement = (
      <Alert className="mt-3" variant="danger">{ errors.non_field }</Alert>
    );
  }

  function renderForm() {
    return (
      <>
        <Form className="auth-form" onSubmit={ onSubmit } loading={ loading }>
          <div className="mb-2 d-flex align-items-center">
            <Icon name="login" className="fs-5 me-2" />
            <span>Preencha seus dados de acesso:</span>
          </div>
          <InputText
            placeholder="E-mail"
            link={ { onChange: change.bind(null, 'email'), value: data.email } }
            error={ getError('email') }
            inputProps={ {
              autoComplete: 'email',
            } }
          />
          <InputPassword
            placeholder="Senha"
            link={ { onChange: change.bind(null, 'password'), value: data.password } }
            error={ getError('password') }
            inputProps={ {
              autoComplete: 'current-password',
            } }
          />
          <div className="d-flex justify-content-between align-items-center">
            <Button type="submit">Entrar</Button>
            <Link className="text-secondary small" to={ routes.passwordRecovery.path }>Esqueci minha senha</Link>
          </div>
        </Form>
        { ErrorElement }
      </>
    );
  }

  return (
    <div className="auth-layout auth-layout-login">
      <div className="auth-form-wrapper">
        <div className="auth-logo-row">
          <PCPSmart addBy />
        </div>
        <div className="auth-form-row">
          { renderForm() }
        </div>
      </div>

      <Contact />
    </div>
  );
}
