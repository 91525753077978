import { Form } from 'components/Form/Form';
import { InputDate } from 'components/Form/InputDate';
import { Icon } from 'components/Icon';
import { FormEvent, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import OmieService from 'services/OmieService';
import { usePOCalculation } from './Context';

export function POCalculationDateSelector() {
  const { state, dispatch, link } = usePOCalculation();
  const [errors, setErrors] = useState<any>({});

  function getError(field: string) {
    if (errors[field] !== undefined) {
      for (const prop in errors[field]) {
        if (Object.prototype.hasOwnProperty.call(errors[field], prop)) {
          return errors[field][prop];
        }
      }
    }
    return null;
  }

  function closeModal() {
    dispatch({
      type: 'setModalVisibility',
      data: false,
    });
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    dispatch({ type: 'setStatus', data: 'pending' });
    OmieService.getCalculation({
      initial_date: state.initial_date instanceof Date ? state.initial_date.toISOString().split('T')[0] : '',
      final_date: state.final_date.toISOString().split('T')[0],
    })
      .then((res) => {
        res.data.products.sort((a, b) => a.descricao.localeCompare(b.descricao));
        dispatch({
          type: 'setCollection',
          data: {
            ind_use_stock: res.data.ind_use_stock,
            productCollection: res.data.products,
          },
        });
        closeModal();
        dispatch({ type: 'setStatus', data: 'success' });
      })
      .catch((err) => {
        dispatch({ type: 'setStatus', data: 'error' });
        if (!err.response) {
          return;
        }
        switch (err.response.status) {
          case 422:
            setErrors({ hasErrors: true, ...err.response.data.error_data });
            break;
          default:
            break;
        }
      });
  }

  return (
    <Modal
      dialogClassName="maxw-590p"
      contentClassName="px-4"
      show={ state.isModalVisible }
      onHide={ closeModal }
    >
      <Form onSubmit={ onSubmit } loading={ state.status === 'pending' }>
        <Modal.Header className="px-0">
          <Modal.Title as="h2" className="h4 fw-bold">
            Calculo de necessidade de materiais
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 py-4">
          <div className="small mb-4">
            Escolha um intervalo, caso não seja informado uma data inicial, será
            considerado todo o período.
          </div>
          <div className="d-flex justify-content-between gap-5">
            <div>
              <div className="text-secondary fw-bold">Data inicial</div>
              <InputDate error={ getError('initial_date') } link={ link('initial_date') } />
            </div>
            <div>
              <div className="text-secondary fw-bold">Data Final</div>
              <InputDate error={ getError('final_date') } link={ link('final_date') } />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start px-0 py-4">
          <Button type="submit">
            <Icon name="calculate" className="fs-5 align-middle" />
            {' '}
            Calcular
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
