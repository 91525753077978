import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import EventManager from 'services/EventManager';
import NotificationService, { ToastNotification } from 'services/NotificationService';

export function Notification() {
  const [notifications, setNotifications] = useState<Array<ToastNotification>>([]);

  useEffect(() => {
    function onAlertsUpdate(newNotifications: Array<ToastNotification>) {
      setNotifications(newNotifications.slice());
    }

    const unsubscribes = [
      EventManager.subscribe(NotificationService.EVENTS.update, onAlertsUpdate),
    ];

    return () => {
      unsubscribes.map((fn) => fn());
    };
  }, []);

  function removeNotification(notification: ToastNotification) {
    NotificationService.remove(notification);
  }

  return (
    <ToastContainer className="p-3 position-fixed" position="top-end">
      { notifications.map((notification: ToastNotification, i: number) => (
        <Toast onClose={ removeNotification.bind(null, notification) } key={ i }>
          <Toast.Header>
            <div className={ `toast-indicator bg-${notification.variant}` } />
            <strong className="ms-2 me-auto">
              { notification.title || ' ' }
            </strong>
          </Toast.Header>
          <Toast.Body>{ notification.body }</Toast.Body>
        </Toast>
      )) }
    </ToastContainer>
  );
}
