import { DynamicTable, DynamicTableColumn } from 'components/DynamicTable';
import { ListTop } from 'components/Layout/ListTop';
import { Paginator } from 'components/Paginator';
import { OmieVendorTypeName } from 'enums/OmieVendorType';
import MysqlToBRDateTime from 'helpers/MysqlToBRDateTime';
import { useList } from 'hooks/useList';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import NotificationService from 'services/NotificationService';
import ProductStructureService, { ProductStructureModel } from 'services/ProductStructureService';

export function ProductStructureList() {
  const [syncing, setSyncing] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' });
  const {
    status,
    collection,
    pagination,
    onSearch,
    onPaginate,
    onSort,
    fetchCollection,
  } = useList<ProductStructureModel>(
    ProductStructureService,
    searchParams,
    setSearchParams,
  );

  async function onSync(ps: ProductStructureModel) {
    setSyncing(true);
    try {
      await ProductStructureService.sync(ps.id || '');
      fetchCollection();
      NotificationService.success(
        'Sincronização iniciada com sucesso.',
        'Sucesso',
      );
    } catch (error) {
      console.error(error);
    }
    setSyncing(false);
  }

  // function onAskToDelete(ps: ProductStructureModel) {

  // }

  const columns: DynamicTableColumn<ProductStructureModel>[] = [
    {
      label: 'Nome',
      field: 'name',
      sortable: true,
      render(productStructure, i) {
        return (
          <div>
            {productStructure.name}
            <br />
            <small className="text-primary">
              (
              {OmieVendorTypeName[productStructure.type]}
              )
            </small>
            {productStructure.last_sync_date ? (
              <>
                <br />
                <small className="text-secondary">
                  Última sincronização:
                  {' '}
                  {MysqlToBRDateTime(productStructure.last_sync_date, true)}
                </small>
              </>
            ) : null}
            {productStructure.pending_sync ? (
              <>
                <br />
                <small className="badge bg-warning">Aguardando processo de sincronização</small>
              </>
            ) : null}
          </div>
        );
      },
    },
    {
      label: 'Estrutura',
      field: 'items',
      render(productStructure, i) {
        return (
          <ul>
            {productStructure.items.map((item) => (
              <li className="border-bottom pt-2 pb-2" key={ item.id }>
                {item.name}
                {' '}
                <small className="text-primary">
                  (
                  {OmieVendorTypeName[item.type]}
                  )
                </small>
                <br />
                <small>
                  Quantidade:
                  {' '}
                  {item.quantity}
                </small>
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      label: '',
      field: 'actions',
      render(productStructure) {
        return (
          <div className="d-flex gap-3">
            <Button
              onClick={ onSync.bind(null, productStructure) }
              variant="outline-primary"
            >
              Sincronizar
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="layout-wrapper layout-wrapper-list">
      <div className="mb-4">
        <ListTop
          q={ searchParams.get('q') || '' }
          onSearch={ onSearch }
          searchText="Buscar produto"
          // toggleFilters={ toggleFilters.bind(null, !isFiltersVisible) }
        />
      </div>
      {/*
      <div className="pb-3">
        <ProductStructureFilters
          isFiltersVisible={ isFiltersVisible }
          linkFilter={ linkFilter }
        />
      </div>
      */}
      <div className="mb-2">
        <DynamicTable
          loading={ status === 'pending' }
          getRowKey={ (resource) => resource.id }
          columns={ columns }
          collection={ collection }
          searchParams={ searchParams }
          onSort={ onSort }
        />
      </div>

      <Paginator
        currentPage={ pagination.currentPage }
        offset={ pagination.offset }
        total={ pagination.total }
        onPaginate={ onPaginate }
      />
    </div>
  );
}
