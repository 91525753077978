import { Notification } from 'components/Notification';
import { Route, Routes } from 'react-router-dom';
import { RouteLayoutItem } from 'types/Routing';

export function App({ layouts }: { layouts: RouteLayoutItem[] }) {
  return (
    <div className="app-root">
      <Routes>
        {layouts.map((item: RouteLayoutItem, index) => {
          if (item.Layout === null) {
            return item.views.map((r) => (
              <Route key={ r.path } path={ r.path } element={ <r.Component /> } />
            ));
          }
          const LayoutElement = item.Layout as () => JSX.Element;
          return (
            <Route key={ index } element={ <LayoutElement /> }>
              {item.views.map((r) => (
                <Route key={ r.path } path={ r.path } element={ <r.Component /> } />
              ))}
            </Route>
          );
        })}
      </Routes>
      <Notification />
    </div>
  );
}
