import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Config } from 'configs';
import { useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { routes } from 'routing/routes';
import AuthService from 'services/AuthService';
import { CustomerUserModel } from 'services/CustomerUserService';
import EventManager from 'services/EventManager';
import Footer from './Footer';
import Header from './Header';

export function DefaultLayout() {
  const [customerUser, setCustomerUser] = useState<CustomerUserModel | null>(
    null,
  );
  const navigation = useNavigate();

  function onLogout() {
    AuthService.logout()
      .then(() => {
        navigation(routes.login.path);
      });
  }

  useLayoutEffect(() => {
    function onUserSet(resCustomerUser: CustomerUserModel) {
      setCustomerUser(resCustomerUser);
    }
    const unsubs = EventManager.subscribe(
      AuthService.EVENTS.userSet,
      onUserSet,
    );
    setCustomerUser(AuthService.getUser());

    return unsubs;
  }, []);

  return (
    <div className="default-layout">
      <Header customerUser={ customerUser } onLogout={ onLogout } />
      <main className="default-layout-main">
        <div className="container-fluid default-layout-container">
          <Outlet />
        </div>
      </main>
      <Footer customerUser={ customerUser } />
      {Config.app.tawkto_id && Config.app.tawkto_id ? (
        <TawkMessengerReact
          propertyId={ Config.app.tawkto_id }
          widgetId={ Config.app.tawkto_widget }
        />
      ) : null}
    </div>
  );
}
