import { Icon } from 'components/Icon';
import { Config } from 'configs';
import MaskPhone from 'helpers/MaskPhone';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export function Contact() {
  return (
    <div>
      <TawkMessengerReact
        propertyId={ Config.app.tawkto_id }
        widgetId={ Config.app.tawkto_widget }
      />
    </div>
  );
  // return (
  //   <div className="auth-contact-wrapper">
  //     <div className="auth-contact-left">
  //       <strong>Fale com a gente:</strong>
  //       <div className="d-flex align-items-center gap-1">
  //         Suporte técnico
  //         {' '}
  //         <a
  //           className="color-inherit"
  //           href={ `https://api.whatsapp.com/send?phone=55${Config.app.whatsapp}` }
  //           target="_blank"
  //           rel="noreferrer noopener"
  //         >
  //           {MaskPhone(Config.app.whatsapp)}
  //         </a>
  //         <Icon name="whatsapp" />
  //       </div>
  //     </div>
  //     <TawkMessengerReact
  //       propertyId={ Config.app.tawkto_id }
  //       widgetId={ Config.app.tawkto_widget }
  //     />
  //   </div>
  // );
}
