import MaskDocument from 'helpers/MaskDocument';
import { CustomerUserModel } from 'services/CustomerUserService';

export default function Footer({ customerUser }: {
  customerUser: CustomerUserModel | null;
}) {
  // const customerId = customerUser ? customerUser.customer_id?.toString() : '';
  return (
    <footer className="footer">
      <div className="footer-company-stripe">
        { customerUser?.customer?.name }
        {' '}
        -
        {' '}
        { MaskDocument(customerUser?.customer?.document, customerUser?.customer?.document_type) }
      </div>
    </footer>
  );
}
