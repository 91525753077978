export function Slugify(str: string): string {
  let ret = str.toLowerCase();
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:; ';
  const to = 'aaaaaeeeeeiiiiooooouuuunc-------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    ret = ret.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  ret = ret
    .replace(/^\s+|\s+$/g, '')
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return ret;
}
