import { POCalculationHeader } from './calculation/Header';
import { POCalculationProvider } from './calculation/Context';
import { POCalculationDateSelector } from './calculation/DateSelector';
import { CalculationList } from './calculation/List';

export function OmieCalculation() {
  return (
    <POCalculationProvider>
      <POCalculationHeader />
      <hr />
      <CalculationList />
      <POCalculationDateSelector />
    </POCalculationProvider>
  );
}
