import { ConfigurationDashboard } from 'views/configurations/Dashboard';
import { Home } from './Home';
import { OmieCalculation } from './Omie/Calculation';
import { OrderHistoryItemList } from './OrderHistoryItem/List';
import { Login } from './auth/Login';
import { PasswordRecovery } from './auth/PasswordRecovery';
import { PasswordReset } from './auth/PasswordReset';
import { CalculationHistoryList } from './calculationHistory/List';
import { CalculationHistoryView } from './calculationHistory/View';
import { DefaultLayout } from './layout/DefaultLayout';
import { Error404 } from './layout/Error404';
import { ProductStructureList } from './productStructure/List';

export const Views = {
  DefaultLayout,
  Home,

  Login,
  PasswordRecovery,
  PasswordReset,

  OmieCalculation,
  CalculationHistoryList,
  OrderHistoryItemList,
  CalculationHistoryView,
  ProductStructureList,
  ConfigurationDashboard,

  Error404,
};
