export default function GetFlattened(
  path: string,
  obj: {[key: string]: any},
  defaultValue: any = null,
) {
  const pathSearch = path.split('.');
  let i; const size = pathSearch.length; let
    response = obj;

  for (i = 0; i < size; i += 1) {
    if (Array.prototype.hasOwnProperty.call(response, pathSearch[i])) {
      response = response[pathSearch[i]] || defaultValue;
    } else {
      return defaultValue;
    }
  }
  return response;
}
