import { AxiosError, AxiosResponse } from 'axios';
import { routes } from 'routing/routes';
import { Config } from 'configs';
import api from './api';
import EventManager from './EventManager';
import CustomerUserService, { CustomerUserModel } from './CustomerUserService';

export class AuthService {
  protected serviceName = 'AuthService';

  private customerUser: CustomerUserModel = CustomerUserService.getDefaultModel();

  private defaultRedirect = '/';

  private intentedRoute: null | string = null;

  private isUserAuthenticated = false;

  public EVENTS = {
    userSet: `${this.serviceName}.user.set`,
  };

  authenticate(data: {
    email: string;
    password: string;
  }): Promise<{
    response: AxiosResponse<{ user: CustomerUserModel }>;
    redirect: string;
  }> {
    return new Promise((resolve, reject) => {
      api
        .get(`${Config.app.api}/v1/csrf-cookie`)
        .then((res: AxiosResponse) => {
          api
            .post('/auth/authenticate', data)
            .then((response) => {
              this.isUserAuthenticated = true;
              this.setUser(response.data);
              // EM.notify(`${this.serviceName}.authenticate`, response, this.intentedRoute);
              resolve({
                response,
                redirect: this.intentedRoute || this.defaultRedirect,
              });
              this.intentedRoute = null;
            })
            .catch((error: AxiosError) => {
              reject(error);
            });
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  }

  passwordRecovery(data: {
    email?: string;
    return_url?: string;
  }): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      api
        .post('/auth/password-recovery', data)
        .then((res) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  passwordReset(data: {
    email: string;
    token: string;
    new_password: string;
    new_password_confirmation: string;
  }): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      api
        .post('/auth/password-reset', data)
        .then((res) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  me(): Promise<AxiosResponse<{ user: CustomerUserModel }>> {
    return new Promise((resolve, reject) => {
      api
        .get('/me')
        .then((res: AxiosResponse) => {
          this.setUser(res.data.user);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  logout(): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      api
        .post('/auth/logout')
        .then((res) => {
          resolve(res);
          this.forgetUser();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  isAuthenticated() {
    return this.isUserAuthenticated;
  }

  setIntentedRoute(route: string) {
    if (route !== routes.login.path) {
      this.intentedRoute = route || null;
    }
  }

  setUser(user: CustomerUserModel) {
    this.customerUser = user;
    EventManager.notify(this.EVENTS.userSet, user);
  }

  getUser() {
    return this.customerUser;
  }

  forgetUser() {
    this.isUserAuthenticated = false;
  }

  getResetPasswordUrl() {
    return `${window.location.origin}${routes.passwordReset.path}`;
  }
}

export default new AuthService();
