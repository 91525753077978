/*eslint-disable */
export default function SetFlattened(
  path: string | string[],
  newValue: any,
  obj: Record<string, any>,
): any {
  if (typeof path === 'string') {
    path = path.split('.');
  }

  if (path.length > 1) {
    const p: any = path.shift();
    if (obj[p] === null || typeof obj[p] !== 'object') {
      obj[p] = {};
    }
    SetFlattened(path, newValue, obj[p]);
  } else {
    obj[path[0]] = newValue;
  }
  return obj;
}
