import { AxiosResponse } from 'axios';
import {
  useEffect, useState,
} from 'react';
import { RestInterface } from 'services/Rest';
import { statuses } from 'types/General';

export function useView<M>(
  service: RestInterface,
  id: string | number,
): {
  status: statuses;
  model: M | false;
} {
  const [status, setStatus] = useState<statuses>('idle');
  const [model, setModel] = useState<M | false>(false);

  useEffect(() => {
    if (!id) return;

    setStatus('pending');
    service.find(id)
      .then((res: AxiosResponse<{data: M}>) => {
        setModel(res.data.data);
        setStatus('success');
      })
      .catch(() => {
        setModel(false);
        setStatus('error');
      });
  }, [service, id]);

  return {
    status,
    model,
  };
}
