import { DynamicTable, DynamicTableColumn } from 'components/DynamicTable';
import { Icon } from 'components/Icon';
import MysqlToBRDate from 'helpers/MysqlToBRDate';
import MysqlToTime from 'helpers/MysqlToTime';
import { useList } from 'hooks/useList';
import { Button } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { routes } from 'routing/routes';
import CalculationHistoryService, { CalculationHistoryModel } from 'services/CalculationHistoryService';

export function CalculationHistoryList() {
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' });
  const {
    status,
    collection,
    pagination,
    // isFiltersVisible,
    // fetchCollection,
    // onSearch,
    onPaginate,
    onSort,
    // linkFilter,
    // toggleFilters,
  } = useList<CalculationHistoryModel>(
    CalculationHistoryService,
    searchParams,
    setSearchParams,
  );
  // const {
  //   deletingModel, deletingStatus, confirmDelete, setDeletingModel,
  // } = useDelete<CalculationHistoryModel>(CustomerUserService, afterDelete);
  // const [formItem, setFormItem] = useState<CalculationHistoryModel | false>(false);

  const columns: DynamicTableColumn<CalculationHistoryModel>[] = [
    {
      label: 'Código',
      field: 'id',
      // style: { width: '170px' },
      thStyle: {
        width: '170px',
        // textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      sortable: true,
    },
    {
      label: 'Data',
      field: 'created_at',
      style: { width: '260px' },
      render: (resource) => (
        <span className="text-nowrap">
          {new Date(resource.created_at).toLocaleDateString('pt-BR', {
            year: 'numeric', month: 'long', day: 'numeric',
          })}
        </span>
      ),
      sortable: true,
    },
    {
      label: 'Hora',
      field: 'created_at',
      style: { width: '260px' },
      render: (resource) => (
        <span className="text-nowrap">
          {new Date(resource.created_at).toLocaleTimeString('pt-BR', {
            hour: '2-digit', minute: '2-digit',
          })}
        </span>
      ),
      sortable: false,
    },
    {
      label: 'Período calculado',
      field: 'query_to',
      render: (resource) => (
        <span>
          { resource.query_from ? new Date(resource.query_from).toLocaleDateString('pt-BR', {
            year: 'numeric', month: 'short', day: 'numeric',
          }) : null }
          {' '}
          até
          {' '}
          { new Date(resource.query_to).toLocaleDateString('pt-BR', {
            year: 'numeric', month: 'short', day: 'numeric',
          }) }
        </span>
      ),
      sortable: true,
    },
    {
      label: '',
      field: 'view',
      style: { width: '1px' },
      render: (resource) => (
        <Link to={ routes.omieCalculationHistoryView.path.replace(/:id/ig, resource.id) }>
          <Icon name="visibility" className="align-middle text-black" />
        </Link>
      ),
    },
  ];

  // function afterDelete() {
  //   setDeletingModel(false);
  //   fetchCollection();
  // }

  // function renderModalContent() {
  //   if (formItem === false) return null;

  //   return (
  //     <>
  //       <Modal.Header className="px-0">
  //         <Modal.Title as="h3">
  //           {formItem.id ? formItem.name : <strong>Novo usuário</strong>}
  //         </Modal.Title>
  //       </Modal.Header>
  //       <CustomerUsersForm
  //         inModal
  //         id={ formItem.id }
  //         afterSave={ () => {
  //           NotificationService.success(
  //             formItem.id ? 'usuário alterado.' : 'Novo usuário criado.',
  //             'Sucesso',
  //           );
  //           setFormItem(false);
  //           fetchCollection();
  //         } }
  //         onCancel={ setFormItem.bind(null, false) }
  //       />
  //     </>
  //   );
  // }

  // function renderDeleteModalContent() {
  //   if (!deletingModel) {
  //     return null;
  //   }

  //   return (
  //     <>
  //       <Modal.Body>
  //         Deseja excluir o usuário
  //         {' '}
  //         {deletingModel.name}
  //         ?
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <div className="d-flex gap-2">
  //           <Button onClick={ confirmDelete } variant="danger">
  //             Excluir
  //           </Button>
  //           <Button
  //             onClick={ setDeletingModel.bind(null, false) }
  //             variant="white"
  //           >
  //             Fechar
  //           </Button>
  //         </div>
  //       </Modal.Footer>
  //     </>
  //   );
  // }

  return (
    <div className="layout-wrapper layout-wrapper-list">
      {/* <div className="mb-4">
        <ListTop
          q={ searchParams.get('q') || '' }
          onSearch={ onSearch }
          searchText="Buscar usuário"
          // toggleFilters={toggleFilters.bind(null, !isFiltersVisible)}
          custom={ (
            <Button
              onClick={ setFormItem.bind(
                null,
                CustomerUserService.getDefaultModel(),
              ) }
              variant="primary"
            >
              <span className="material-icons material-icons-outlined fs-5 align-middle me-2">
                person_add
              </span>
              <span className="align-middle">Novo usuário</span>
            </Button>
          ) }
        />
      </div> */}

      <div className="pb-3">
        {/* <CustomersFilters
          isFiltersVisible={isFiltersVisible}
          linkFilter={linkFilter}
          // searchParams={searchParams}
        /> */}
      </div>

      <div className="mb-2">
        <DynamicTable
          loading={ status === 'pending' }
          getRowKey={ (resource) => resource.id }
          columns={ columns }
          collection={ collection }
          searchParams={ searchParams }
          onSort={ onSort }
        />
      </div>
      {/*

      <Paginator
        currentPage={ pagination.currentPage }
        offset={ pagination.offset }
        total={ pagination.total }
        onPaginate={ onPaginate }
      />

      <Modal
        dialogClassName="mw-1100p"
        contentClassName="p-3"
        show={ formItem !== false }
        onHide={ setFormItem.bind(null, false) }
      >
        {renderModalContent()}
      </Modal>

      <Modal
        show={ deletingModel !== false }
        onHide={ setDeletingModel.bind(null, false) }
      >
        {renderDeleteModalContent()}
      </Modal> */}
    </div>
  );
}
