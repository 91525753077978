import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouteItem, RouteLayoutItem } from 'types/Routing';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { App } from 'App';
import { DefaultLayout } from 'views/layout/DefaultLayout';
import ObjectFind from 'helpers/ObjectFind';
import { createBrowserHistory } from 'history';
import { AuthMiddleware } from './middlewares';

const history = createBrowserHistory();

export default function Init(routes: Record<string, RouteItem>, cb?: () => void) {
  const DOMRootNode = createRoot(document.getElementById('root') as HTMLElement);

  const parsedLayouts: RouteLayoutItem[] = [
    { Layout: DefaultLayout, views: [] },
    { Layout: null, views: [] },
  ];
  for (const key in routes) {
    if (Object.prototype.hasOwnProperty.call(routes, key)) {
      const R: RouteItem = routes[key];
      switch (R.Layout) {
        case undefined:
          parsedLayouts[0].views.push(R);
          break;
        case null:
          parsedLayouts[1].views.push(R);
          break;
        default:
          const result = ObjectFind(parsedLayouts, 'layout', R.Layout);
          if (result.index === -1) {
            parsedLayouts.push({ Layout: R.Layout, views: [R] });
          } else {
            parsedLayouts[result.index].views.push(R);
          }
          break;
      }
    }
  }

  AuthMiddleware(history);

  DOMRootNode.render(
    <StrictMode>
      <HistoryRouter history={ history }>
        <App layouts={ parsedLayouts } />
      </HistoryRouter>
    </StrictMode>,
  );

  if (cb !== undefined) {
    cb();
  }
}
