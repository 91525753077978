import { Views } from 'views';
// import { RouteItem } from "types/Routing";

export const routes = {
  home: { path: '/', title: 'Dashboard', Component: Views.Home },

  omieCalculation: {
    path: '/calcular-oc-op',
    title: 'Calcular OP + OC',
    Component: Views.OmieCalculation,
  },

  omieCalculationHistory: {
    path: '/calculo-de-oc-op/historico',
    title: 'Histórico de gerações de OP + OC',
    Component: Views.CalculationHistoryList,
  },

  orderHistoryItem: {
    path: '/historico-de-vendas',
    title: 'Histórico de vendas',
    Component: Views.OrderHistoryItemList,
  },

  configurationDashboard: {
    path: '/configuracoes',
    title: 'Configurações',
    Component: Views.ConfigurationDashboard,
  },

  omieCalculationHistoryView: {
    path: '/calculo-de-oc-op/historico/:id',
    title: 'Histórico de gerações de OP + OC',
    Component: Views.CalculationHistoryView,
  },

  productStructures: {
    path: '/produtos/estruturas',
    title: 'Estruturas de produtos',
    Component: Views.ProductStructureList,
  },

  login: {
    path: '/autenticacao/login',
    title: 'Login',
    Component: Views.Login,
    Layout: null,
  },
  passwordRecovery: {
    path: '/autenticacao/recuperar-senha',
    title: 'Recuperar senha',
    Component: Views.PasswordRecovery,
    Layout: null,
  },
  passwordReset: {
    path: '/autenticacao/resetar-senha',
    title: 'Resetar senha',
    Component: Views.PasswordReset,
    Layout: null,
  },

  _404: {
    path: '*',
    title: 'Página não encontrada',
    Component: Views.Error404,
    Layout: null,
  },
};
