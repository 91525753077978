import MaskCNPJ from './MaskCNPJ';
import MaskCPF from './MaskCPF';

export default function MaskDocument(document?: string, type?: 'CPF' | 'CNPJ' | 'RNM' | string) : string {
  switch (type) {
    case 'CNPJ':
      return MaskCNPJ(document);
    case 'CPF':
      return MaskCPF(document);
    default:
      return document || '';
  }
}
