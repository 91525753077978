// import { Button } from "components/Layout/Button";
// import { Route } from "helpers/Route";
import Ilustration from 'assets/images/undraw-not-found.svg';
import { Link } from 'react-router-dom';
import { routes } from 'routing/routes';

export function Error404() {
  return (
    <div className="flex flex-col justify-center items-center gap-6 py-5 px-5">
      <div className="text-8xl font-semibold">404</div>
      <div className="text-lg">Ops, não encontramos a página que você esta procurando</div>
      <Link to={ routes.home.path }>Voltar para a página inicial</Link>
      <img src={ Ilustration } width="480" height="333" alt="Página não encontrada" />
    </div>
  );
}
