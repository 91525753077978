import {
  FormEvent, useEffect, useState,
} from 'react';
import {
  Button, Form, FormCheck, Modal, Spinner,
} from 'react-bootstrap';
import { CustomerModel } from 'services/CustomerService';
import NotificationService from 'services/NotificationService';
import OmieService, { Etapa, EtapaFaturamento } from 'services/OmieService';

export function StepsConfiguration(
  {
    customer,
    submit,
  } : {
     customer: CustomerModel,
     submit: (data: any) => void
  },
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [checkboxes, setCheckboxes] = useState<{
    cCodigo: string,
    cDescricao: string,
    cCodOperacao: string
  }[]>([]);
  const [etapaFaturamento, setEtapaFaturamento] = useState<EtapaFaturamento | null>(null);

  useEffect(() => {
    setCheckboxes(customer.settings.steps);
  }, [customer.settings.steps]);

  function openModal() {
    setModalVisibility(true);
    setLoading(true);
    OmieService.listarEtapasFaturamento()
      .then((res) => {
        setEtapaFaturamento(res.data);
      })
      .catch((err) => {
        NotificationService.danger('Ops, não conseguimos buscar as etapas.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function closeModal() {
    setModalVisibility(false);
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    submit({ steps: checkboxes });
  }

  function getCheckboxIndex(cCodigo: string) : number {
    const result = checkboxes.findIndex((step) => step.cCodigo === cCodigo);
    return result;
  }

  function onChangeCheckbox(etapa: Etapa) {
    const index = getCheckboxIndex(etapa.cCodigo);
    const newCheckboxes = checkboxes.slice();
    if (index > -1) {
      newCheckboxes.splice(index, 1);
    } else if (etapaFaturamento) {
      newCheckboxes.push({
        cCodOperacao: etapaFaturamento.cCodOperacao,
        cCodigo: etapa.cCodigo,
        cDescricao: etapa.cDescricao,
      });
    }
    setCheckboxes(newCheckboxes);
  }

  function renderForm() {
    if (loading) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="grow" />
        </div>
      );
    }
    return (
      <Form onSubmit={ onSubmit }>
        <ul className="d-flex gap-4 flex-wrap list-unstyled">
          { etapaFaturamento && etapaFaturamento.etapas.map((etapa) => (
            <li key={ etapa.cCodigo }>
              <FormCheck
                id={ etapa.cCodigo }
                onChange={ onChangeCheckbox.bind(null, etapa) }
                value={ etapa.cCodigo }
                label={ etapa.cDescricao }
                checked={ getCheckboxIndex(etapa.cCodigo) > -1 }
              />
            </li>
          )) }
        </ul>
        <hr />
        <div className="d-flex gap-3 justify-content-end">
          <Button
            onClick={ closeModal }
            variant="white"
          >
            Fechar
          </Button>
          <Button
            onClick={ closeModal }
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </Form>
    );
  }

  return (
    <>
      <div className="text-uppercase mb-2">
        <strong>Selecionar etapas dos pedidos de venda</strong>
      </div>
      <div>
        <p>
          Selecione as Etapas dos Pedidos de Venda que
          deverão ser considerados como &rdquo;Pedidos a Faturar&rdquo;
        </p>
        <Button
          onClick={ openModal }
          className="text-nowrap"
        >
          <span>Gerênciar</span>
        </Button>
      </div>

      <Modal show={ isModalVisible } onHide={ closeModal }>
        <Modal.Header>
          <Modal.Title>Selecione as etapas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { renderForm() }
        </Modal.Body>
      </Modal>
    </>
  );
}
