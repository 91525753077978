import { CalculationHistoryItemModel } from './CalculationHistoryItemService';
import { Model, Rest } from './Rest';

export interface CalculationHistoryModel extends Model {
  ind_use_stock?: boolean;
  query_from?: string;
  query_to: string;
  customer_id: number;
  items: CalculationHistoryItemModel[];
  created_at: string;
  updated_at: string;
}

export class CalculationHistoryService extends Rest {
  resourceable_as = 'App\\Models\\CalculationHistory';

  getDefaultModel(merge?: Record<string, any>): CalculationHistoryModel {
    return {
      query_to: '',
      customer_id: 0,
      updated_at: '',
      created_at: '',
      items: [],
      ...merge,
    };
  }
}

export default new CalculationHistoryService('CalculationHistoryService', '/calculations-history');
