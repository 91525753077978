import { DynamicTable, DynamicTableColumn } from 'components/DynamicTable';
import { ListTop } from 'components/Layout/ListTop';
import { Paginator } from 'components/Paginator';
import { useList } from 'hooks/useList';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routing/routes';
import { OmieService } from 'services/OmieService';
import OrderHistoryItemService, { OrderHistoryItemModel } from 'services/OrderHistoryItemService';
import { OrderHistoryItemFilters } from './Filters';

export function OrderHistoryItemList() {
  const [relations, setRelations] = useState<{[key: string]: unknown[]}>({
    calculations: [],
    sale_forecasts: [],
  });
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' });
  const {
    status,
    collection,
    pagination,
    isFiltersVisible,
    setStatus,
    onSearch,
    onPaginate,
    onSort,
    linkFilter,
    changeFilter,
    toggleFilters,
  } = useList<OrderHistoryItemModel>(
    OrderHistoryItemService,
    searchParams,
    setSearchParams,
  );

  useEffect(() => {
    setStatus('pending');
    OrderHistoryItemService.fetchRelations('index')
      .then((res) => {
        setStatus('success');
        setRelations(res.data);
        if (res.data.calculations.length) {
          changeFilter('calculation_history_id', res.data.calculations[0].id);
        }
      })
      .catch(() => {
        setStatus('error');
      });
  }, []);

  const columns: DynamicTableColumn<OrderHistoryItemModel>[] = [
    // {
    //   label: 'COD',
    //   field: 'id',
    //   style: { width: '60px', textAlign: 'center' },
    //   thStyle: {
    //     width: '60px',
    //     textAlign: 'center',
    //     whiteSpace: 'nowrap',
    //   },
    //   sortable: true,
    // },
    {
      label: 'Pedido',
      field: 'vendor_order_number',
      sortable: true,
    },
    {
      label: 'Produto',
      field: 'name',
      sortable: true,
    },
    {
      label: 'Cliente',
      field: 'customer_name',
      sortable: true,
    },
    {
      label: 'Sped',
      field: 'vendor_type',
      render: (resource) => (
        OmieService.PRODUCT_TYPE_MAP[resource.vendor_type].name
      ),
      sortable: true,
    },
    {
      label: 'Tipo',
      field: 'vendor_type',
      render: (resource) => (
        OmieService.isProduction(resource.vendor_type) ? 'Próprio' : 'Terceiros'
      ),
    },
    {
      label: 'Vendas',
      field: 'sold_qty',
      sortable: true,
    },
    {
      label: 'Insumos',
      field: 'sold_as_feedstock_qty',
      sortable: true,
    },
    // {
    //   label: 'Nº pedido de venda',
    //   field: 'vendor_order_number',
    //   sortable: true,
    // },
    {
      label: 'Faturar',
      field: 'sale_forecast',
      render: (resource) => (
        resource.sale_forecast
          ? new Date(resource.sale_forecast).toLocaleDateString('pt-BR')
          : null
      ),
      sortable: true,
    },
    {
      label: 'Cálculo',
      field: 'calculation_history_id',
      thStyle: { textAlign: 'center' },
      render: (resource) => (
        <div className="d-flex justify-content-center">
          <Link to={ routes.omieCalculationHistoryView.path.replace(/:id/ig, resource.calculation_history?.id) }>
            { new Date(resource.calculation_history?.created_at || '').toLocaleDateString('pt-BR', {
              minute: '2-digit',
              hour: '2-digit',
            }) }
          </Link>
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <div className="layout-wrapper layout-wrapper-list">
      <div className="mb-4">
        <ListTop
          q={ searchParams.get('q') || '' }
          onSearch={ onSearch }
          searchText="Buscar produto"
          toggleFilters={ toggleFilters.bind(null, !isFiltersVisible) }
        />
      </div>

      <div className="pb-3">
        <OrderHistoryItemFilters
          relations={ relations }
          isFiltersVisible={ isFiltersVisible }
          linkFilter={ linkFilter }
        />
      </div>

      <div className="mb-2">
        <DynamicTable
          loading={ status === 'pending' }
          getRowKey={ (resource) => resource.id }
          columns={ columns }
          collection={ collection }
          searchParams={ searchParams }
          onSort={ onSort }
        />
      </div>

      <Paginator
        currentPage={ pagination.currentPage }
        offset={ pagination.offset }
        total={ pagination.total }
        onPaginate={ onPaginate }
      />
    </div>
  );
}
