import { Form } from 'components/Form/Form';
import { InputText } from 'components/Form/InputText';
// import { Route } from "helpers/Route";
import { ChangeEvent, ReactNode, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
// import QueryString from "qs";
import { InputPassword } from 'components/Form/InputPassword';
import { PCPSmart } from 'assets/PCPSmart';
import AuthService from 'services/AuthService';
import { Link, useSearchParams } from 'react-router-dom';
import { routes } from 'routing/routes';
import { Icon } from 'components/Icon';
import { Contact } from './Contact';

export function PasswordReset() {
  const [sp] = useSearchParams();
  let ErrorElement: ReactNode;
  const [data, setData] = useState({
    email: '',
    new_password: '',
    new_password_confirmation: '',
    token: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [resetRequested, setResetRequested] = useState(false);

  function change(field: string, e: ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [field]: e.target.value });
  }

  function getError(field: string) {
    if (errors[field] !== undefined) {
      for (const prop in errors[field]) {
        if (Object.prototype.hasOwnProperty.call(errors[field], prop)) {
          return errors[field][prop];
        }
      }
    }
    return null;
  }

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();
    setLoading(true);
    AuthService.passwordReset({ ...data, token: sp.get('token') || '' }).then((resData: any) => {
      setErrors({});
      setResetRequested(true);
    }).catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 422:
            setErrors(err.response.data.error_data);
            break;
          default:
            break;
        }
      }
      setLoading(false);
    });
  }

  if (errors.non_field) {
    ErrorElement = (
      <Alert className="mt-3" variant="danger">{ errors.non_field }</Alert>
    );
  }

  function renderForm() {
    if (resetRequested) {
      return (
        <div className="text-center">
          <Alert variant="success">
            <strong>Resetado!</strong>
            {' '}
            você ja pode acessar sua conta com a nova senha.
          </Alert>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <Link className="text-secondary small" to={ routes.login.path }>Voltar para tela de login</Link>
            <Icon name="login" className="fs-5 me-2" />
          </div>
        </div>
      );
    }
    return (
      <>
        <Form className="auth-form" onSubmit={ onSubmit } loading={ loading }>
          <div className="mb-2">
            <span>Reset de senha:</span>
          </div>
          <InputText
            placeholder="Digite o seu e-mail usado no cadastro"
            link={ { onChange: change.bind(null, 'email'), value: data.email } }
            error={ getError('email') }
            inputProps={ {
              autoComplete: 'email',
            } }
          />
          <InputPassword
            placeholder="Nova senha"
            link={ { onChange: change.bind(null, 'new_password'), value: data.new_password } }
            error={ getError('new_password') }
            inputProps={ {
              autoComplete: 'new-password',
            } }
          />
          <InputPassword
            placeholder="Confirmação da nova senha"
            link={ { onChange: change.bind(null, 'new_password_confirmation'), value: data.new_password_confirmation } }
            error={ getError('new_password_confirmation') }
            inputProps={ {
              autoComplete: 'new-password',
            } }
          />
          <Button type="submit">Resetar</Button>
        </Form>
        { ErrorElement }
      </>
    );
  }

  return (
    <div className="auth-layout auth-layout-password-reset">
      <div className="auth-form-wrapper">
        <div className="auth-logo-row">
          <PCPSmart addBy />
        </div>
        <div className="auth-form-row">
          { renderForm() }
        </div>
      </div>

      <Contact />
    </div>
  );
}
