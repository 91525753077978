import { AxiosError, AxiosResponse } from 'axios';
import { Icon } from 'components/Icon';
import MysqlToBRDateTime from 'helpers/MysqlToBRDateTime';
import { useState } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { routes } from 'routing/routes';
import CustomerService, { CustomerModel } from 'services/CustomerService';
import NotificationService from 'services/NotificationService';
import ProductStructureService from 'services/ProductStructureService';

type ProductStructureSyncConfigurationProps = {
  customer: CustomerModel;
  fetchMe: () => void;
};

export function ProductStructureSyncConfiguration({
  customer,
  fetchMe,
}: ProductStructureSyncConfigurationProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isClearMalhasModalVisible, setClearMalhasModalVisibility] = useState<boolean>(false);

  function syncProductStructure() {
    setLoading(true);
    CustomerService.syncProductStructure()
      .then(() => {
        NotificationService.success('Processo iniciado com sucesso.');
        fetchMe();
      })
      .catch((err) => {
        NotificationService.danger('Ops, não conseguimos iniciar o processo.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function confirmDelete() {
    setLoading(true);
    ProductStructureService.clear()
      .then((res: AxiosResponse) => {
        NotificationService.success('Malhas excluídas com sucesso.');
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        NotificationService.danger('Ops, não conseguimos excluir as malhas.');
        setLoading(false);
      })
      .finally(() => {
        setClearMalhasModalVisibility(false);
      });
  }

  function openModal() {
    setClearMalhasModalVisibility(true);
  }

  function closeModal() {
    setClearMalhasModalVisibility(false);
  }

  return (
    <div>
      <div className="text-uppercase mb-2">
        <strong>Sincronizar malhas</strong>
      </div>
      <div>
        <p>
          Verifica se há atualizações de malhas e sincroniza com sua base de dados para o cálculo
          { customer.settings.omie_produto_estutura_updated_at ? (
            <small className="d-block text-primary">
              Última atualização:
              {' '}
              {MysqlToBRDateTime(customer.settings.omie_produto_estutura_updated_at, true)}
            </small>
          ) : null }
        </p>
        <div className="d-flex gap-4">
          <Button onClick={ syncProductStructure } className="text-nowrap" disabled={ loading } aria-disabled={ loading }>
            {loading ? (
              <span>
                <Spinner animation="grow" size="sm" />
                {' '}
                Iniciando...
                {' '}
              </span>
            ) : 'Sincronizar'}
          </Button>
          <Button variant="outline-primary" href={ routes.productStructures.path } className="text-nowrap">
            Visalizar struturas
          </Button>
        </div>
        <div>
          <div className="text-uppercase mt-4 mb-2">
            <strong>Excluir todas as malhas</strong>
          </div>
          <p>
            Ao clicar em &quot;limpar malhas&quot; todos os dados de malhas
            serão perdidos definitivamente.
          </p>
          <Button
            onClick={ openModal }
            variant="outline-danger"
            className="text-nowrap"
          >
            <Icon name="delete" className="fs-5 align-middle me-2" />
            <span>limpar malhas</span>
          </Button>
        </div>
      </div>
      <Modal show={ isClearMalhasModalVisible } onHide={ closeModal }>
        <Modal.Body>
          <Alert variant="danger">
            <div className="mb-2">Deseja limpar todas as malhas do sistema?</div>
            <strong>ATENÇÃO ESTA AÇÃO É IRREVERSÍVEL</strong>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button onClick={ confirmDelete } variant="danger">
              Confirmar
            </Button>
            <Button
              onClick={ closeModal }
              variant="white"
            >
              Fechar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
