import { Spinner } from 'react-bootstrap';

export function Form(
  {
    loading, children, onSubmit, className = '',
  }:
    {
        loading?: boolean,
        children: any,
        onSubmit?: any,
        className?: string,
    },
) {
  return (
    <div className="loading-wrapper">
      { loading ? <div className="loading-wrapper-element"><Spinner animation="grow" /></div> : null }
      <form className={ className } onSubmit={ onSubmit }>
        { children }
      </form>
    </div>
  );
}
