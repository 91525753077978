import SelectFilter from 'components/Filters/SelectFilter';
import { TextFilter } from 'components/Filters/TextFilter';
import { Col, Collapse, Row } from 'react-bootstrap';
import { OmieService } from 'services/OmieService';
import { FilterLinkCallable } from 'types/List';

export function OrderHistoryItemFilters({
  isFiltersVisible,
  relations,
  linkFilter,
}: {
  isFiltersVisible: boolean;
  relations: any;
  linkFilter: FilterLinkCallable;
}) {
  function getSalesForecastOptionLabel(item: any) {
    return new Date(item.sale_forecast).toLocaleDateString('pt-BR');
  }

  function getSalesForecastOptionValue(item: any) {
    return item.sale_forecast;
  }

  function getCalculationHistoryIdOptionLabel(item: any) {
    return new Date(item.created_at).toLocaleDateString('pt-BR', {
      minute: '2-digit',
      hour: '2-digit',
    });
  }

  return (
    <Collapse in={ isFiltersVisible }>
      <div className="mb-3">
        <Row>
          <Col md="3">
            <TextFilter
              placeholder="Pedido"
              link={ linkFilter('vendor_order_number') }
            />
          </Col>
          <Col md="3">
            <TextFilter
              placeholder="Cliente"
              link={ linkFilter('customer_name') }
            />
          </Col>
          <Col md="3">
            <SelectFilter
              placeholder="Sped"
              link={ linkFilter('vendor_type') }
              options={ OmieService.PRODUCT_TYPE_OPTIONS }
            />
          </Col>
          <Col md="3">
            <SelectFilter
              placeholder="Tipo"
              link={ linkFilter('vendor_type') }
              options={ [
                { id: OmieService.PRODUCTION_CODES.join(','), name: 'Próprio' },
                { id: OmieService.BOUGHT_CODES.join(','), name: 'Terceiros' },
              ] }
            />
          </Col>
          <Col md="3">
            <SelectFilter
              placeholder="Origem"
              link={ linkFilter('ind_feedstock', '=') }
              options={ [
                { id: 0, name: 'Pedido de venda' },
                { id: 1, name: 'Insumos' },
              ] }
            />
          </Col>
          <Col md="3">
            <TextFilter
              placeholder="Quantidade"
              link={ linkFilter('sold_qty') }
            />
          </Col>
          <Col md="3">
            <SelectFilter
              placeholder="Faturar"
              link={ linkFilter('sale_forecast') }
              options={ relations.sale_forecasts }
              getOptionLabel={ getSalesForecastOptionLabel }
              getOptionValue={ getSalesForecastOptionValue }
            />
          </Col>
          <Col md="3">
            <SelectFilter
              hideEmptyOption
              placeholder="Cálculo"
              link={ linkFilter('calculation_history_id') }
              options={ relations.calculations }
              getOptionLabel={ getCalculationHistoryIdOptionLabel }
            />
          </Col>
        </Row>
      </div>
    </Collapse>
  );
}
// Ordenar por suprimentos
